import React, { useState } from "react";
import { Layout } from "../components/StyledComponents";
import Page from "../routes/PageLayout";
import { DropSheetModal } from "../components/Modal";

const TestPage = () => {
  const [active, setActive] = useState(false);
  console.log(active);
  return (
    <Page title="Test Page" isFooter={false}>
      <div className="menu__spacer"></div>
      <DropSheetModal />
      <button onClick={() => setActive(true)}>Show modal</button>

      <Layout>
        <h1>Test Page</h1>
      </Layout>
    </Page>
  );
};
export default TestPage;
