import { motion } from "framer-motion";
import React from "react";
import { RiStoreFill } from 'react-icons/ri';
import { Link } from "react-router-dom";
import HeroBlade from "../components/Hero";
import { Layout } from "../components/StyledComponents/index";
import UsesCircularView from "../components/UsesCircle";
import { Circle_Menu_Icons } from "../components/utils";
import Page from "../routes/PageLayout";
import { getCurrentLanguage } from "../store/selector";
import '../styles/index.scss';

export const MainHomePage = () => {
  const TRANS = window.TRANS;
  return (
    <Page title="ARM & HAMMER™">
      <HeroBlade />
      <div className="category__grid">
        <UsesCircularView area={Circle_Menu_Icons} />
      </div>
      <Layout className="explore__button__wrapper">

        <div className="custom__button">
          <Link to={`/${getCurrentLanguage()}/explore/`}>
            <motion.button whileTap={{ scale: 0.8 }}>
              {TRANS.Explore_Use}
            </motion.button>
          </Link>
        </div>

        <div className="custom__button" >
          <a href="https://shop.armandhammerarabia.com/" >
            <motion.button whileTap={{ scale: 0.8 }}>
              <RiStoreFill />
              {TRANS.Shop_now}
            </motion.button>
          </a>
        </div>
      </Layout>
    </Page>
  );
}

