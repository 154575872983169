import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import ImageSkeleton from "../Skeleton/ImageSkeleton";
import "./hero.carousel.scss";

const HeroCarousel = ({ id, duration = 4000, indicators = false }) => {
  const { data, isEnglish } = useSelector((state) => state.database);

  const carouselContent = data?.Promotions;
  return (
    <>
      {carouselContent ? (
        <div className="swiper__hero__carousel">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={12}
            slidesPerView={1}
            pagination={{
              el: `#${id}`,
              clickable: true,
            }}
            autoplay={{
              delay: duration,
            }}
            autoHeight={false}
          >
            {Object.entries(carouselContent).filter(([_key, _val]) => {
              if (isEnglish) return _val.Media_Culture === 'en-US';
              else return _val.Media_Culture === 'ar-AE'
            }).map(([key, value]) => (
              <SwiperSlide key={value.Promotion_ID}>
                <motion.div
                  className="carousel__hero__content"
                  whileTap={{ scale: 0.98 }}
                >
                  {value?.Promotion_Link ? (
                    <a href={value.Promotion_Link} rel="noreferrer" target="_blank">
                      <img src={IMAGE_SERVER_PATH + value.Media_Source} alt="hero-background" />
                    </a>
                  ) : (
                    <img src={IMAGE_SERVER_PATH + value.Media_Source} alt="hero-background" />
                  )}
                  {value?.title && (
                    <div className="hero__overlay__text">{value.title}</div>
                  )}
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
          {indicators && <div id={id}></div>}
        </div>
      ) : (
        <ImageSkeleton height={240} />
      )}
    </>
  );
};
export default React.memo(HeroCarousel);
