import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  flattenData,
  LANGUAGE
} from "../../components/utils";
import { AR_LANG, EN_LANG } from "../../routes/PathConfig";
import { getCurrentLanguage } from "../selector";
import { DATA_API } from "./constants";

const initialState = {
  isDataLoading: false,
  data: null,
  posts: null,
  language: EN_LANG.toUpperCase(),
  isEnglish: true,
  isLoggedIn: false,
  hasLiked: { category: [], subcategory: [] },
  searchData: null,
};

// const postAnalyticsData = (REF_NODE, VIEWS = 0, TYPE = "pageViews") => {
//   // update(ref(database, REF_NODE), { [TYPE]: VIEWS + 1 });
// };

export const dbSignInAnonymously = createAsyncThunk("database/dbSignInAnonymously", async () => {
  // const auth = getAuth();
  // signInWithEmailAndPassword(auth, DB_USER.username, DB_USER.password)
  //   .then(() => {})
  //   .catch((error) => {
  //     console.log(error);
  //   });
});

export const updateViews = createAsyncThunk("database/updateViews", async (data, { getState }) => {
  // const userPrefs = localStorage.getItem(PREFS);
  // !userPrefs && localStorage.setItem(PREFS, JSON.stringify(USER_INITIAL_PREFS));

  // const localPrefs = JSON.parse(localStorage.getItem(PREFS));
  // const language = getCurrentLanguage().toUpperCase();
  // const FLAG_CATEGORY = localPrefs?.[language]?.pageViews?.[CATEGORY].includes(data?.category);

  // if (data?.page === CATEGORY) {
  //   if (!FLAG_CATEGORY && ALL_CATEGORIES.includes(data?.category)) {
  //     localPrefs[language].pageViews[CATEGORY] = [
  //       ...localPrefs[language].pageViews[CATEGORY],
  //       data.category,
  //     ];
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));

  //     const CAT_REF = `/data/${language}/content/${data?.category}/coverData`;
  //     get(child(ref(database), CAT_REF + "/pageViews"))
  //       .then((snapshot) => {
  //         if (snapshot.exists()) postAnalyticsData(CAT_REF, snapshot.val());
  //         else postAnalyticsData(CAT_REF);
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // } else if (data?.page === SUBCATEGORY) {
  //   const FLAG_SUBCATEGORY = localPrefs?.[language]?.pageViews?.[SUBCATEGORY].includes(
  //     data?.subcategory
  //   );
  //   if (!FLAG_SUBCATEGORY && data?.subcategory) {
  //     localPrefs[language].pageViews[SUBCATEGORY] = [
  //       ...localPrefs[language].pageViews[SUBCATEGORY],
  //       data.subcategory,
  //     ];
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
  //     const SUBCATEGORY_REF = `/data/${language}/content/${data?.category}/usesData/${data?.subcategory}`;
  //     get(child(ref(database), SUBCATEGORY_REF + "/pageViews"))
  //       .then((snapshot) => {
  //         if (snapshot.exists()) postAnalyticsData(SUBCATEGORY_REF, snapshot.val());
  //         else postAnalyticsData(SUBCATEGORY_REF);
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }
  return;
});

export const updateLikes = createAsyncThunk("database/updateLikes", async (data, { getState }) => {
  // const userPrefs = localStorage.getItem(PREFS);
  // !userPrefs && localStorage.setItem(PREFS, JSON.stringify(USER_INITIAL_PREFS));

  // const localPrefs = JSON.parse(localStorage.getItem(PREFS));
  // const language = getCurrentLanguage().toUpperCase();
  // const FLAG_CATEGORY = localPrefs?.[language]?.pageLikes?.[CATEGORY].indexOf(data?.category);

  // if (data?.page === CATEGORY) {
  //   if (FLAG_CATEGORY === -1 && data?.category) {
  //     localPrefs[language].pageLikes[CATEGORY] = [
  //       ...localPrefs[language]?.pageLikes[CATEGORY],
  //       data.category,
  //     ];
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
  //     const CAT_REF = `/data/${language}/content/${data?.category}/coverData`;
  //     get(child(ref(database), CAT_REF + "/pageLikes"))
  //       .then((snapshot) => {
  //         if (snapshot.exists()) postAnalyticsData(CAT_REF, snapshot.val(), "pageLikes");
  //         else postAnalyticsData(CAT_REF, 0, "pageLikes");
  //       })
  //       .catch((error) => console.log(error));
  //   } else if (FLAG_CATEGORY > -1 && data?.category) {
  //     localPrefs[language].pageLikes[CATEGORY].splice(FLAG_CATEGORY, 1);
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
  //   }
  // } else if (data?.page === SUBCATEGORY) {
  //   const FLAG_SUBCATEGORY = localPrefs?.[language]?.pageLikes?.[SUBCATEGORY].indexOf(
  //     data?.subcategory
  //   );
  //   if (FLAG_SUBCATEGORY === -1 && data?.subcategory) {
  //     localPrefs[language].pageLikes[SUBCATEGORY] = [
  //       ...localPrefs?.[language]?.pageLikes[SUBCATEGORY],
  //       data.subcategory,
  //     ];
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
  //     const SUBCATEGORY_REF = `/data/${language}/content/${data?.category}/usesData/${data?.subcategory}`;
  //     get(child(ref(database), SUBCATEGORY_REF + "/pageLikes"))
  //       .then((snapshot) => {
  //         if (snapshot.exists()) postAnalyticsData(SUBCATEGORY_REF, snapshot.val(), "pageLikes");
  //         else postAnalyticsData(SUBCATEGORY_REF, 0, "pageLikes");
  //       })
  //       .catch((error) => console.log(error));
  //   } else if (FLAG_SUBCATEGORY > -1 && data?.subcategory) {
  //     localPrefs[language].pageLikes[SUBCATEGORY].splice(FLAG_SUBCATEGORY, 1);
  //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
  //   }
  // }
  // return localPrefs[language].pageLikes;
});

export const fetchInstagramPosts = createAsyncThunk("database/fetchInstagramPosts", async (lastSyncedFeed) => {
    // if (lastSyncedFeed) {
    //   const current = new Date().getTime();
    //   const diff = current - lastSyncedFeed;
    //   const compute = diff / (1000 * 3600);
    //   //refresh token for every 6 hours once...
    //   if (Math.abs(Math.round(compute)) >= 6) {
    //     const URL = __IG__GRAPHQL__ + __IG__;
    //     const data = await fetch(URL)
    //       .then((raw) => raw.json())
    //       .then((res) => res?.data)
    //       .catch((err) => console.log(err));
    //     if (data)
    //       update(ref(database, "/data/instagramPosts"), {
    //         data,
    //         updatedAt: new Date().getTime(),
    //       }).then(() => {
    //         const localData = JSON.parse(sessionStorage.getItem("data"));
    //         localData.instagramPosts.data = data;
    //         sessionStorage.setItem("data", JSON.stringify(localData));
    //       });
    //     return data;
    //   }
    // }
  }
);

export const fetchData = createAsyncThunk("database/fetchData", async (props, { dispatch, getState }) => {
    const localData = sessionStorage.getItem("data");
    if(localData){ return JSON.parse(localData)}
     const language = getCurrentLanguage().toUpperCase();
    try {
      return await fetch(DATA_API.getAppContent).then(d => d.json())
      .then(data => {
        if (data.status === 200) {
          sessionStorage.setItem("data", JSON.stringify(data.response));
          localStorage.setItem(LANGUAGE, language);
          return data.response;
        }
      }).catch((error) => {
        console.error(error);
      });

    } catch(err) {
      console.err(err)
    }
  }
);

const databaseSlice = createSlice({
  name: "database",
  initialState,
  reducers: {
    loggedInState: (state, { payload }) => {
      // state.isLoggedIn = payload;
    },
    updateLanguage: (state) => {
      const lang = getCurrentLanguage().toUpperCase();
      state.language = lang;
      state.isEnglish = lang === 'EN';

      function setARLang(){
        document.body.classList.add("AR"); 
        document.body.setAttribute('dir', 'rtl')
      }

      lang === AR_LANG.toUpperCase()
        ? setARLang()
        : document.body.classList.remove("AR");
    },
  },
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.isDataLoading = true;
    },
    [fetchData.fulfilled]: (state, { payload }) => {
      state.posts = payload?.instagramPosts;
      state.isDataLoading = false;
      state.data = payload;
      state.searchData = flattenData(payload);
    },
    [fetchData.rejected]: (state) => {
      state.isDataLoading = false;
      state.data = null;
    },
    [fetchInstagramPosts.fulfilled]: (state, { payload }) => {
      state.posts = payload;
    },
    [updateLikes.fulfilled]: (state, { payload }) => {
      state.hasLiked = payload;
    },
  },
});
export const { loggedInState, updateLanguage } = databaseSlice.actions;
export default databaseSlice.reducer;
