import ImageMap from "image-map";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { getCurrentLanguage } from "../../store/selector";
import RippleAnimation from "./RippleAnimation";
import "./uses.circle.scss";

const UsesCircularView = ({ area }) => {
  const navigate = useNavigate();

  //update-coordinates
  useEffect(() => {
    setTimeout(() => {
      ImageMap("img[usemap]");
    }, 500);
  }, []);

  const clickHandler = (link) => {
    const formattedLink = `/${getCurrentLanguage()}/${link}`
    navigate(formattedLink);
    trackEvent('Circular_Menu_'+ formattedLink );
  };

  return (
    <>
      <div className="circular__container">
        <img src="/assets/public/circle/circular__backdrop.png" alt="100-uses" className="img-fluid uses__props" />
        <img src="/assets/public/circle/circular__eng__copy.png" alt="100-uses" className="img-fluid position-relative circular__copy" />
        <div className="uses__circle__container uses__props">
          <img src="/assets/public/circle/circular__icons.png" useMap="#uses__circle__map" alt="map-points" className="img-fluid" />
          <map name="uses__circle__map">
            {area?.map(({ id: { link }, cords }, index) => (
              <area title={link} coords={cords} shape="rect" alt={link} key={index + cords} onClick={() => clickHandler(link)} />
            ))}
          </map>
        </div>
        <RippleAnimation className="img-fluid uses__props circular__anim" />
      </div>
    </>
  );
};

export default React.memo(UsesCircularView);
