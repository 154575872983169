export const TRANS_EN = {
    Language : 'English',
    Follow_Us : "Follow us",
    Copy_right_content:"Arm & Hammer. All rights reserved.",
    Nav_back : "Back",
    Explore_Use : "Explore 100 Uses",
    Shop_now : "Shop Now",
    List_products : "List of all products",
    No_bookmark : "No Bookmarks found!",
    Freequent : "Frequent Questions",
    Freequent_desc : "If you have a question, chances are someone else has asked the same one. That's why we compiled our most frequently asked questions right    here. Just select the category and find your answer.",
    email_support :"Email Support",
    Home : "Home",
    Bookmarks : "Bookmarks",
    Products : " Products",
    Shop : "Shop",
    HelpCenter : "Help Center",
    AboutUs : "About Us",
    others : "other",
    faq1 : "What is the shelf life for Baking Soda?",
    faq1desc : "The shelf life is three years. The expiration date is located on the bottom of the box and pouch.",
    faq2 : "Can I use Baking Soda and Clear Balance for my pool?",
    faq2desc : "Yes, we recommend using Baking Soda initially to raise pH and alkalinity, and then following with regular use of Clear Balance to maintain levels.",
    faq3 : "How often should the box of Baking Soda in the fridge and/or freezer be changed?",
    faq3desc : "We recommend changing the box in your refrigerator/freezer once a month for optimal freshness.",
    faq4 : "What is the difference between Baking Soda and Baking Powder?",
    faq4desc : "Baking soda is 100% pure sodium bicarbonate. Baking powder is a mixture of baking soda (usually about 28-30%) and various acidic ingredients (singularly or in combination). Both are used as leavening agents in baked goods- baking soda will react with an acid, giving off carbon dioxide gas and causing dough to rise. However, they are used in different situations and are not interchangeable. Baking soda is typically used in a recipe that has an acidic ingredient (cream of tartar, buttermilk, etc.) that will react with the soda to give off carbon dioxide. Baking powder, which contains one or more acidic ingredients to facilitate the reaction, is used in recipes without acidic ingredients.",
    faq5 : "How does the box differ from the shaker?",
    faq5desc : "While the baking soda used in the Shaker package is 100% pure ARM & HAMMER™ Baking Soda, it is a different granulation than what is found in the Yellow Box. This different granulation makes the baking soda more free flowing and less prone to caking and lumping, particularly around high moisture conditions such as kitchen cleaning.",
    faq6 : "How does Baking Soda differ from Super Washing Soda?",
    faq6desc : "Baking Soda is made of 100% Sodium Bicarbonate. Super Washing Soda is made of 100% Sodium Carbonate. While they sound similar, they are not the same. Both products can be used to improve liquid laundry performance for cleaner, fresher clothes. Both products can also be used for cleaning around the house. Baking Soda can be used in baking, as a dentifrice and as an antacid, Super Washing Soda cannot. Super Washing Soda should never be ingested. Be sure to check product packaging for specific uses and recommendations.",
    faq7 : "When used as a dentifrice how abrasive is baking soda?",
    faq7desc : "Baking soda provides a mild abrasive action to help polish, clean and deodorize the teeth. It is the natural choice for highly effective personal products such as toothpastes, mouth wash and gargle.",
    faq8 : "How does Baking Soda help sooth poison ivy and bee stings?",
    faq8desc : "Baking Soda temporarily protects and helps relieve minor skin irritation and itching by neutralizing toxins and irritants at the skin’s surface. Follow package directions listed under Drug Facts for skin protectant.",
    faq9 : "What is “dental grade” baking soda?",
    faq9desc : "Dental Grade Baking Soda has a special particle size to provide optimum cleaning with low abrasion.",
    Help : "Help & Support",
    household_uses : "Household Uses", 
    bathroom_uses : "Bathroom Uses",
    kitchen_uses : "Kitchen Uses",
    personal_care : "Personal Care",
    outdoor_uses : "Terrace & Garage",
    pet_care : "Pet Care",
    baby_care : "Baby Care",
    fun_for_kids : "Fun For Kids",
    housedesc : "(Laundry, Carpets..)",
    bathroomdesc : "(Drains, tiles..)",
    kitchendesc : "(fridge, sink..)",
    personaldesc : "(face, hands..)",
    teeracedesc : "(car, lawn..)",
    petdesc : "(cat, dog..)",
    babydesc : "(toys, bottles..)",
    kidsdesc : "(clay, colors..)",
    feeling : "Feeling Lucky!",
    feelingdesc : "View any random use from the list!",
    Next : 'Next',
    Prev: 'Prev',
    ViewAll: 'View All',
    Search : "Search",
    SearchPlaceholder : "Search...",
    RecentSearch : "Recent Search",
    SearchResults : "Search Results",
    LearnMore: 'Learn more',
    YourBookmarks: 'Your Bookmarks',
    ThisPageWillOpenInAnother : 'This page will open another application.',
    Cancel: 'Cancel',
    Open: 'Open'
}