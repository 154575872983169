import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/StyledComponents";
import Page from "../routes/PageLayout";
import { INDEX } from "../routes/PathConfig";

const PageNotFound = () => {
  return (
    <Page title="Page not found">
      <div className="menu__spacer"></div>
      <Layout>
        <div className="text-center py-5">
          <h1>Page Not Found, 404</h1>
          <Link to={INDEX}>Back to Home page</Link>
          <div className="pt-5">
          <img src="/assets/Error.svg" alt="bg" className="img-fluid" />
          </div>
        </div>
      </Layout>
    </Page>
  );
};
export default PageNotFound;
