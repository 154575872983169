import { createSlice } from "@reduxjs/toolkit";
import { DARK_THEME, DEFAULT_THEME } from "../../components/utils";

export const rootSlice = createSlice({
  name: "app",
  initialState: {
    selectedTheme: DEFAULT_THEME,
    menuState: false,
    appTitle: "ARM & HAMMER™",
    bookmarks: null,
    displayType: "circle",
    favourites: [],
  },

  reducers: {
    themeSelector: (state) => {
      const t = state.selectedTheme === DEFAULT_THEME ? DARK_THEME : DEFAULT_THEME;
      state.selectedTheme = t;
    },

    menuToggle: (state, { payload }) => {
      const body = document.body;
      payload ? body.classList.add("overflow__hidden") : body.classList.remove("overflow__hidden");
      state.menuState = payload;
    },

    appBarTitle: (state, { payload }) => {
      state.appTitle = payload;
    },

    storeBookmark: (state, { payload }) => {
      const favourites = localStorage.getItem('favourites')
      state.favourites = !!favourites ? JSON.parse(favourites) : [];
      // const userPrefs = localStorage.getItem(PREFS);
      // !userPrefs && localStorage.setItem(PREFS, JSON.stringify(USER_INITIAL_PREFS));
      // const localPrefs = JSON.parse(localStorage.getItem(PREFS));
      // const language = localStorage.getItem(LANGUAGE);
      // if (payload) {
      //   const BOOKMARK_INDEX = localPrefs?.[language]?.bookmarks?.ID?.indexOf(payload);
      //   if (BOOKMARK_INDEX === -1 && payload) {
      //     localPrefs[language].bookmarks.ID = [...localPrefs?.[language]?.bookmarks.ID, payload];
      //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
      //     state.bookmarks = localPrefs?.[language].bookmarks;
      //   } else if (BOOKMARK_INDEX > -1 && payload) {
      //     localPrefs?.[language].bookmarks.ID.splice(BOOKMARK_INDEX, 1);
      //     localStorage.setItem(PREFS, JSON.stringify(localPrefs));
      //     state.bookmarks = localPrefs?.[language].bookmarks;
      //   }
      // } else {
      //   state.bookmarks = localPrefs?.[language]?.bookmarks;
      // }
    },

    changeDisplayType: (state, { payload }) => {
      state.displayType = payload;
    },
    setUserFavourites: (state, { payload }) => {
      const usesData = payload;
      const favourites = [...state.favourites];
    
      const index = favourites.findIndex(uses => uses.Uses_ID === usesData.Uses_ID);
      if (index !== -1) {
        favourites.splice(index, 1);
      } else {
        favourites.push(usesData);
      }
      localStorage.setItem('favourites', JSON.stringify(favourites));
      state.favourites = favourites;
    }
  },
});

export const { themeSelector, menuToggle, appBarTitle, storeBookmark, changeDisplayType, setUserFavourites } = rootSlice.actions;
export default rootSlice.reducer;
