import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { trackEvent } from "../analytics";
import SearchCards from "../components/Cards/SearchCards";
import { SearchInput, Suggestion } from "../components/Search";
import { Layout } from "../components/StyledComponents";
import { CATEGORY, TITLE, USES_TITLE } from "../components/utils";
import Page from "../routes/PageLayout";

const Search = () => {
  const { searchData, isEnglish } = useSelector((state) => state.database);
  const searchStorage = localStorage.getItem("searchQuery");
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [noResults, setNoResults] = useState(false);

  const searchQuery = (value) => {
    inputRef.current.value = value;
    setSearchValue(value);
    setLoader(true);
    trackEvent('Search_'+value);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value?.length > 3) {
      setLoader(true);
      trackEvent('UserSearching_'+ e.target.value);
    } else {
      setData([]);
      setLoader(false);
    }
  };

  const clearInputHandler = () => {
    inputRef.current.value = "";
    setSearchValue();
    setLoader(false);
    setData([]);
  };

  const clearRecentSearchHandler = () => {
    localStorage.removeItem("searchQuery");
    setSearchValue("");
  };


  const querySearchData = () => {
    setNoResults(false);
    setTimeout(() => {
      const query = searchValue.toLowerCase();
      const searchQuery = [];
      searchData.filter((item) => {
        if (item?.Category_ID?.includes(query)) return searchQuery.push({ ...item, found: CATEGORY });
        else if ((isEnglish ? item.Title : item.Title_Ar ?? '')
          .toLowerCase().includes(query)) return searchQuery.push({ ...item, found: TITLE });
        else if ((isEnglish ? item.Description_Title : item.Description_Title_Ar ?? '')
          .toLowerCase().includes(query)) return searchQuery.push({ ...item, found: USES_TITLE });
        else return null;
      });
      //store search queries in localStorage...
      let data = [{ id: uuid().split("-")[0], query: searchValue }];
      let found = false;
      if (searchStorage) {
        const __recentData = JSON.parse(searchStorage);
        __recentData.forEach(({ query }) => {
          if (query.includes(searchValue)) found = true;
        });
        if (found) data = [...__recentData];
        else data = [...data, ...__recentData];
      }
      localStorage.setItem("searchQuery", JSON.stringify(data));
      if (searchQuery.length > 0) setData(searchQuery);
      else setNoResults(true);
      setLoader(false);
    }, 1000);
  };

  useEffect(() => {
    if (searchValue?.length > 3) {
      querySearchData();
    }
    //eslint-disable-next-line
  }, [searchValue]);
  const TRANS = window.TRANS; 

  return (
    <Page title={TRANS.Search} isFooter={false}>
      <div className="menu__spacer"></div>
      <Layout>
        <SearchInput
          inputRef={inputRef}
          value={searchValue}
          clearInput={clearInputHandler}
          isLoading={loader}
          onChangeText={searchInputHandler}
          data={data}
        />
        {data.length === 0 && (
          <>
            {searchStorage && (
              <Suggestion
                title={TRANS.RecentSearch}
                search={(val) => searchQuery(val)}
                canDelete={true}
                onDelete={clearRecentSearchHandler}
                searchItems={JSON.parse(searchStorage)}
              />
            )}
            {/* <Suggestion searchItems={searchSuggestions} search={(val) => searchQuery(val)} title={"Recommendation"} /> */}
          </>
        )}
        {data.length > 0 && (
          <>
            <div className="my-3">
              <div className="d-flex justify-content-between">
                <h2>{TRANS.SearchResults}</h2>
                <h4 className="results__count">{data.length}</h4>
              </div>
              <SearchCards data={data} isEnglish={isEnglish}/>
            </div>
          </>
        )}
        {noResults && (
          <div className="mt-2 no__results__image">
            <img src="/assets/public/norecordfound.png" alt="Search Results" className="img-fluid" />
          </div>
        )}
      </Layout>
    </Page>
  );
};
export default Search;
