import React, { useState } from "react";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import ImageSkeleton from "../Skeleton/ImageSkeleton";
import "./category.hero.scss";

const CategoryHero = ({ image, alt }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  return (
    <>
      {!isMediaLoaded && <ImageSkeleton height={360} />}
      <div className={`category__hero ${isMediaLoaded ? "d-block" : "d-none"}`}>
        <img className="category__img" src={IMAGE_SERVER_PATH + image} alt={alt} onLoad={() => setIsMediaLoaded(true)} />
      </div>
      <div className="category__hinge"></div>
    </>
  );
};
export default CategoryHero;
