import { motion } from "framer-motion";
import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { IoIosShareAlt } from "react-icons/io";
import { trackEvent } from "../../analytics";
import { handleNativeShare } from "../utils";
import "./title.card.scss";

const TitleCard = ({ title, handleLike, hasLiked, pageLikes, pageViews }) => {
  const handleShare = () => {
    handleNativeShare(title, window.location.href);
    trackEvent('Share');
  };

  return (
    <>
      <div className="title__card__container shadow-sm">
        <div className="title__card__title">
          <h1>{title}</h1>
        </div>
        <div className="title__card__card__body">
          <div className="title__card__metrics__system">
            {/* <div className="title__card__likes">
              <AiFillHeart /> <span>{formatCount(pageLikes)}</span>
            </div>
            <div className="title__card__views">
              <AiFillEye /> <span>{formatCount(pageViews)}</span>
            </div> */}
          </div>
          <div className="title__card__external">
            <motion.button
              className={`title__card__share category__like ${hasLiked ? "fill__heart" : ""}`}
              whileTap={{ scale: 0.8 }}
              onClick={handleLike}
            >
              <AiFillHeart />
            </motion.button>
            <motion.button className="title__card__share" whileTap={{ scale: 0.8 }} onClick={handleShare}>
              <IoIosShareAlt />
            </motion.button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TitleCard;
