import React, { useEffect, useState } from "react";
import { BsGlobe2 } from "react-icons/bs";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TRANS_AR } from "../../lang/AR";
import { TRANS_EN } from "../../lang/EN";
import { AR_LANG, EN_LANG, INDEX, SEARCH } from "../../routes/PathConfig";
import { menuToggle } from "../../store/reducer/app";
import { getCurrentLanguage } from "../../store/selector";
import { LanguageModal } from "../Modal";
import { Layout } from "../StyledComponents";
import { socialLinks } from "../utils";
import "./header.footer.scss";

export const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menu = useSelector((state) => state.app.menuState);
  const appTitle = useSelector((state) => state.app.appTitle);

  const menuToggleHandler = () => {
    dispatch(menuToggle(!menu));
  };

  const canGoBack = () => {
    if (location.pathname === SEARCH) {
      navigate(INDEX);
    } else {
      navigate(-1);
    }
  };

  const handleWindowScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
  }, []);

  const switchLanguage = () => {
    const language = getCurrentLanguage() === EN_LANG ? AR_LANG : EN_LANG;
    window.history.pushState(null, null, `/${language}/`);
    setTimeout(() => { window.location.reload()}, 100);
    
  }
  const TRANS = window.TRANS;
  return (
    <>
      <LanguageModal active={activeModal} setActive={setActiveModal} />
      <div className={`header__bar header__fixed header__app ${!scrolled ? "header__bar__detached" : ""}`}>
        {(location.pathname === `/${EN_LANG}/` ||location.pathname === `/${AR_LANG}/`)  ? (
          <>
            <div className="nav__menu" onClick={menuToggleHandler}>
              <HiOutlineMenuAlt2 />
            </div>
            <div className="header__title">{appTitle}</div>

            <div className="nav__menu__icons">
              {/* <img src="/assets/AR_FLAG.svg" alt="AR Flag" className="flag__lang"/> */}
              <BsGlobe2 />
              <p className="nav__lang" onClick={switchLanguage}>
                {getCurrentLanguage() === EN_LANG ? TRANS_AR.Language : TRANS_EN.Language}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="nav__menu nav__back" onClick={canGoBack}>
              <IoIosArrowBack />
              <span>{TRANS.Nav_back}</span>
            </div>
            <div className="header__title appbar__title">{appTitle}</div>
            <div className="header__bleed"></div>
          </>
        )}
      </div>
    </>
  );
};

export const Footer = () => {
  const TRANS = window.TRANS;
  return (
    <>
      <Layout>
        <div className="footer__container mt-5 mb-3 p-4">
          <div className="footer__social__links">
            <div className="footer__title">{TRANS.Follow_Us} </div>
            <div className="footer__links">
              <div className="footer__icon">
                <a href={socialLinks.facebook} rel="nofollow noreferrer" target="_blank">
                  <img src="/assets/public/facebook.svg" alt="social" />
                </a>
              </div>
              <div className="footer__icon">
                <a href={socialLinks.instagram} rel="nofollow noreferrer" target="_blank">
                  <img src="/assets/public/instagram.svg" alt="social" />
                </a>
              </div>
              <div className="footer__icon">
                <a href={socialLinks.youtube} rel="nofollow noreferrer" target="_blank">
                  <img src="/assets/public/youtube.svg" alt="social" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="copyright-mark margin-t-40 margin-b-30">
          <div className="content">
            <img className="logo-gray" src="/assets/public/anh-logo.png" alt="logo" />
            <p className="margin-b-20">© {new Date().getFullYear()} {TRANS.Copy_right_content} </p>
          </div>
        </section>

        <div className="pb-5"></div>
      </Layout>
    </>
  );
};
