import React from "react";
import "./heroblade.scss";

const HeroBlade = () => {
  return (
    <>
      <div className="hero__blade">
        {/* <div className="hero__logo">
          <img src="/assets/public/anh-logo.png" alt="hero-logo" />
        </div>
        <div className="hero__content">
          <h1>100</h1>
          <p>Unbelievable Uses of</p>
          <p>
            <strong>Baking Soda</strong>
          </p>
        </div> */}
      </div>
    </>
  );
};
export default HeroBlade;
