import { createGlobalStyle } from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const GlobalStyles = createGlobalStyle`
    body  { 
        transition : all .50s ease;
        color : ${({ theme }) => theme.primaryTextColor};
    } 
`;
export default GlobalStyles;
