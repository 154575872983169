import React, { useEffect, useState } from "react";

const Loader = () => {
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadError(true);
    }, 15000);
  }, []);

  const forceReload = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="anh__splash">
        <div className="anh__splash__img">
          <img src="/assets/public/anh-logo.png" alt="logo" />
          <div className="loading__wrapper">
            <div className="loader"></div>
          </div>
        </div>
        {loadError && (
          <div className="loading__error">
            <p className="text-danger">
              Something went wrong, please relaunch your browser or application and try again.
            </p>
            <button className="btn btn-primary w-100 mt-3" onClick={forceReload}>
              Reload
            </button>
            <p className="text-center loader__internet">
              Make sure you have internet connectivity!
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default Loader;
