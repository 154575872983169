import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { trackEvent } from "../analytics";
import { Header } from "../components/Header_Footer";
import BottomNavigation from "../components/Header_Footer/BottomNavigation";
import Loader from '../components/Misc/Loader';
import Drawer from "../components/SideDrawer/Drawer";
import { DEFAULT_THEME } from "../components/utils";
import { TRANS_AR } from "../lang/AR";
import { TRANS_EN } from "../lang/EN";
import { fetchData, updateLanguage } from "../store/reducer/database";
import { getCurrentLanguage } from "../store/selector";
import GlobalStyles from "../styles/Global";
import ThemeConfig from "../styles/Theme";
import { EN_LANG } from "./PathConfig";
import PageRoutes from "./Routes";

const AppRouter = () => {
  const dispatch = useDispatch();
  const isFirstRun = useRef(true);
  const selectedTheme = useSelector((state) => state.app.selectedTheme);
  // const isDataLoading = useSelector((state) => state.database.isDataLoading);
  
  /**
   * If the default path is '/' then redirect to default culture english!
   */
  const rootPath = window.location.pathname === "/";
  if(rootPath) window.history.pushState(null, null, '/en/');


  useEffect(() => {
    if (isFirstRun.current) {
      dispatch(fetchData());
      dispatch(updateLanguage());
      isFirstRun.current = false;
    }
  }, [dispatch]);
  
  if(!window.TRANS) window.TRANS = getCurrentLanguage() === EN_LANG ? TRANS_EN : TRANS_AR;
  if (window.innerWidth >= 769) {
    trackEvent('APP_REDIRECTED_TO_DESKTOP_SITE');
    window.location.href = 'https://armandhammerarabia.com/100-uses-of-baking-soda/';
    return <Loader />
  }
  
  return (
    <ThemeProvider theme={selectedTheme ? ThemeConfig?.[selectedTheme] : DEFAULT_THEME}>
      <GlobalStyles />
      <BrowserRouter>
        <React.Fragment>
          <Header />
          <BottomNavigation />
          <Drawer />
        </React.Fragment>
        <PageRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRouter;
