import React from "react";
import { useSelector } from "react-redux";
import ProductCard from "../components/Cards/ProductCards";
import { Layout } from "../components/StyledComponents/index";
import Page from "../routes/PageLayout";

const Products = () => {
  const { data, isEnglish } = useSelector((state) => state.database);
  const TRANS = window.TRANS;
  return (
    <Page title={TRANS.Products}>
      <Layout className="menu__spacer">
        <h6 style={{ marginBottom: "1rem" }}> {TRANS.List_products}</h6>
        {data?.Products &&
          Object.entries(data?.Products).map(([key, item]) => (
            <ProductCard key={item.Product_ID} item={item} isEnglish={isEnglish}/>
          ))}
      </Layout>
    </Page>
  );
};

export default Products;
