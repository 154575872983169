import React from "react";
import "./skeleton.scss";
import ContentLoader from "react-content-loader";
import ImageSkeleton from "./ImageSkeleton";

const SubCategorySkeleton = (props) => {
  return (
    <>
      <ImageSkeleton height={360} />
      <div className="category__hinge"></div>
      <div className="skeleton-container">
        <div className="sub-container marginTop shadow-sm">
          <ContentLoader speed={2} width={540} height={125} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
            <rect x="15" y="25" rx="9" ry="9" width="80%" height="20" />
            <rect x="15" y="57" rx="9" ry="9" width="50%" height="20" />
            <rect x="15" y="98" rx="9" ry="9" width="65%" height="20" />
          </ContentLoader>
        </div>
        <div className="sub-container shadow-sm">
          <ContentLoader speed={2} width={540} height={70} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
            <circle cx="37" cy="42" r="22" />
            <rect x="73" y="22" rx="9" ry="9" width="75%" height="15" />
            <rect x="73" y="47" rx="9" ry="9" width="60%" height="15" />
          </ContentLoader>
        </div>
        <div className="sub-container shadow-sm">
          <ContentLoader speed={2} width={540} height={70} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
            <circle cx="37" cy="42" r="22" />
            <rect x="73" y="22" rx="9" ry="9" width="75%" height="15" />
            <rect x="73" y="47" rx="9" ry="9" width="60%" height="15" />
          </ContentLoader>
        </div>
      </div>
    </>
  );
};

export default SubCategorySkeleton;
