import React, { useEffect, useState } from "react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { trackEvent } from "../analytics";
import DesCard from "../components/Cards/DesCard";
import MediaCard from "../components/Cards/MediaCard";
import Carousel from "../components/Carousels/Carousel";
import { LayoutSection } from "../components/Misc";
import SubCategorySkeleton from "../components/Skeleton/SubCategorySkeleton";
import { Layout } from "../components/StyledComponents/index";
import PageNotFound from "../pages/PageNotFound";
import Page from "../routes/PageLayout";
import { PRODUCTS } from "../routes/PathConfig";
import { setUserFavourites, storeBookmark } from "../store/reducer/app";

const SubCategory = () => {
  const { category, subcategory, lang } = useParams();
  const dispatch = useDispatch();
  const { data, isDataLoading, isEnglish } = useSelector((state) => state.database);
  const { favourites } = useSelector((state) => state.app);
  const [bookmark, setBookmark] = useState(false);
  const navigate = useNavigate();
  const [node, setNode] = useState({ prev: null, next: null });
  // const hasBookmarked = JSON.parse(localStorage.getItem(PREFS))?.[language].bookmarks?.ID;

  useEffect(() => {
    // dispatch(updateViews({ page: SUBCATEGORY, category, subcategory }));
    // setBookmark(hasBookmarked?.includes(subcategory));
    // setLike(hasLiked?.includes(subcategory));

    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    if (data?.BakingSodaContent?.[category]?.UsesData) {
      const _data = Object.entries(data?.BakingSodaContent?.[category]?.UsesData);
      const _index = _data.findIndex(([key]) => key === subcategory);
      setNode({ prev: _data[_index - 1]?.[0], next: _data[_index + 1]?.[0] });
    }
    // eslint-disable-next-line
  }, [data]);

  const handleLikesClick = () => {
    dispatch(setUserFavourites(usesData));
    // setLike(!like);
    trackEvent('Liked_' + subcategory);
  };

  const handleBookmark = () => {
    dispatch(storeBookmark(subcategory));
    setBookmark(!bookmark);
    trackEvent('Bookmarked_' + subcategory);
  };

  const usesData = data?.BakingSodaContent?.[category]?.UsesData?.[subcategory];
  const hasLiked = favourites.some(f => f.Uses_ID === usesData?.Uses_ID);

  const navigationHandler = (direction) => {
    const navigationLink = `/${lang}/explore/${category}/${node[direction]}`;
    navigate(navigationLink);
    trackEvent('Page_Navigations_' + navigationLink);
  }

  if (data?.content && !usesData) {
    return <PageNotFound />;
  }

  const TRANS = window.TRANS;

  return (
    <Page className="sub__category__page" title={isEnglish ? usesData?.Title : usesData?.Title_Ar || "Sub Category"} 
    {...(usesData?.Media_Type === 'image' && { imageSource: usesData?.Media_Source })}
    >
      {usesData && !isDataLoading ? (
        <>
          <Layout className="menu__spacer">
            <MediaCard
              isImage={usesData?.Media_Type === "image"}
              mediaSource={usesData?.Media_Source}
              title={isEnglish ? usesData?.Title : usesData.Title_Ar}
              handleLike={handleLikesClick}
              hasLiked={hasLiked}
              hasBookmarked={bookmark}
              handleBookmark={handleBookmark}
              pageViews={usesData?.pageViews || 1}
              pageLikes={usesData?.pageLikes || 1}
            />
            <DesCard
              usesDescription={isEnglish ? usesData?.Description_Title : usesData?.Description_Title_Ar}
              usesList={usesData?.UsesContent}
              usesIcon={category}
              isEnglish={isEnglish}
            />

            <div className="node__action__container bok-next-prev margin-t-40 margin-b-40">
              {node?.prev && (
                <div
                  className="prev visited"
                  onClick={() => navigationHandler('prev')}
                >
                  <span className="me-3">{TRANS.Prev}</span>
                  <div className="icon">
                    <RiArrowLeftLine />
                  </div>
                </div>
              )}
              {node?.next && (
                <div className="next"onClick={() => navigationHandler('next')}>
                  <div className="icon">
                    <RiArrowRightLine />
                  </div>
                  <span>{TRANS.Next}</span>
                </div>
              )}
            </div>
          </Layout>
          {/* <LayoutSection title="You may also like" link="/random" bg={false}>
            <Carousel source={heroBanners} id="home__recommendation" />
          </LayoutSection> */}
          <LayoutSection title={TRANS.Products} link={PRODUCTS} bg={false}>
            <Carousel source={data?.Products} id="home__products" isEnglish={isEnglish}/>
          </LayoutSection>
        </>
      ) : (
        <Layout className="menu__spacer">
          <SubCategorySkeleton />
        </Layout>
      )}
    </Page>
  );
};

export default SubCategory;
