import { motion } from "framer-motion";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { IoCloseOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import { getCurrentLanguage } from "../../store/selector";
import ImageSkeleton from "../Skeleton/ImageSkeleton";

const FeelingLuckyModal = ({ modalState, setModalState }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const { data, isEnglish } = useSelector((state) => state.database);
  const closeModalWindow = () => {
    setModalState({ state: false, data: null });
    setIsMediaLoaded(false);
    trackEvent('Feeling_lucky_' + data?.BakingSodaContent?.[modalState?.data?.category]+modalState?.data?.subCategory)
  };
  const usesData = data?.BakingSodaContent?.[modalState?.data?.category]?.UsesData?.[modalState?.data?.subCategory];
  const TRANS = window.TRANS;
  return (
    <>
      {modalState.data &&
        usesData &&
        ReactDOM.createPortal(
          <div className={`lang__modal__wrapper ${modalState.state ? "show" : "hide"}`}>
            <div className="lang__modal__overlay" onClick={closeModalWindow}></div>
            <div className="lang__modal__container">
              <div className="lang__modal__close" onClick={closeModalWindow}>
                <IoCloseOutline />
              </div>
              <div className="lang__modal__body mt-5">
                <div className="feeling__lucky__context">
                  <div className="media__source">
                    {usesData.Media_Type === "image" ? (
                      <>
                        {!isMediaLoaded && <ImageSkeleton height={200} />}
                        <div className={`${isMediaLoaded ? "d-block" : "d-none"} w-h-100`}>
                          <img src={IMAGE_SERVER_PATH + usesData.Media_Source} alt={usesData.Title} onLoad={() => setIsMediaLoaded(true)} />
                        </div>
                      </>
                    ) : (
                      <>
                        {!isMediaLoaded && <ImageSkeleton height={200} />}
                        <div className={`media__card__video ${isMediaLoaded ? "d-block" : "d-none"}`}>
                          <iframe
                            src={usesData.Media_Source}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={usesData.Title}
                            onLoad={() => setIsMediaLoaded(true)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="tag tag__green category__tag">{usesData.Category_ID.toUpperCase()}</div>
                  <div className="lang__modal__context mb-3">
                    <h4>{isEnglish ? usesData.Title : usesData.Title_Ar}</h4>
                  </div>
                  <Link to={`/${getCurrentLanguage()}/explore/${modalState?.data?.category}/${modalState?.data?.subCategory}`} 
                    onClick={closeModalWindow}>
                    <motion.button whileTap={{ scale: 0.8 }} className="btn btn-primary ">
                      {TRANS.LearnMore}
                    </motion.button>
                  </Link>
                </div>
              </div>
            </div>
          </div>,
          document.getElementById("feeling__lucky__modal")
        )}
    </>
  );
};
export default FeelingLuckyModal;
