import React from "react";
import { RiArrowDropRightLine, RiMailOpenLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { FAQ } from "../components/utils";
import Page from "../routes/PageLayout";

const Help = () => {
  const TRANS = window.TRANS; 
  return (
    <Page title={TRANS.Help}>
      <div className="menu__spacer"></div>
      <div className="help-search-support"></div>

      <section className="about-section">
        <div className="un-navMenu-default without-icon py-0">
          <ul className="nav flex-column">
            <motion.a href="mailto:armandhammerarabia@gmail.com" className="anchor__reset" whileTap={{ scale: 0.8 }}>
              <li className="nav-item mb-0">
                <div className="nav-link visited bg-white">
                  <div className="item-content-link">
                    <div className="icon color-green w-auto">
                      <RiMailOpenLine />
                    </div>
                    <h3 className="link-title">{TRANS.email_support}</h3>
                  </div>
                  <div className="other-cc">
                    <span className="badge-text"></span>
                    <div className="icon-arrow">
                      <RiArrowDropRightLine />
                    </div>
                  </div>
                </div>
              </li>
            </motion.a>
          </ul>
        </div>
        <div className="descriptio">
          <h2> {TRANS.Freequent}</h2>
          <p>
          {TRANS.Freequent_desc}
            
          </p>
        </div>
        <div className="accordion padding-x-20" id="FAQ__accordion">
          {FAQ.map(({ title, id, desc }) => (
            <React.Fragment key={id}>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}`}
                    aria-expanded="false"
                    aria-controls={id}
                  >
                    {title}
                  </button>
                </h2>
                <div id={id} className="accordion-collapse collapse" data-bs-parent="#FAQ__accordion">
                  <div className="accordion-body">{desc}</div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </section>
    </Page>
  );
};
export default Help;
