import { getCurrentLanguage } from '../store/selector';

export const EN_LANG = 'en';
export const AR_LANG = 'ar';
const language = getCurrentLanguage();

export const INDEX = "/*";
export const HOME = "/:lang/explore";
export const CATEGORY = "/:lang/explore/:category";
export const SUBCATEGORY = "/:lang/explore/:category/:subcategory";
export const PRODUCTS = `/${language}/products`;
export const PRODUCT = "/:lang/product/:product";
export const BOOKMARKS = "/:lang/bookmarks";
export const HELP = `/${language}/help`;
export const TEST_PATH = "/test";
export const _404 = "*";
export const ABOUT_US = "about";
export const Uses = {
  HouseHoldUses: { link: "explore/household-uses" },
  KitchenUses: { link: "explore/kitchen-uses" },
  BathroomUses: { link: "explore/bathroom-uses" },
  PersonalCare: { link: "explore/personal-care" },
  Outdoors: { link: "explore/outdoor-uses" },
  PetCare: { link: "explore/pet-care" },
  BabyCare: { link: "explore/baby-care" },
  FunForKids: { link: "explore/fun-for-kids" },
};
export const SEARCH = "/:lang/search";
export const INSTAGRAM_URL = "https://www.instagram.com/armandhammerarabia/";
