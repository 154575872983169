export const GTAG_ID = "G-1CWTH88CBY";

// log the pageView with their URL
export const pageView = (url) => {
  const locale = localStorage.getItem("locale") || "EN";
  window.gtag("config", GTAG_ID, {
    page_path: url,
    language: locale,
  });
};

// const event = ({ action, _params }) => {
//   const locale = localStorage.getItem("locale") || "EN";
//   const params = { ..._params, locale };
//   window.gtag("event", action, params);
// };

// log specific events happening.
export const trackEvent = (eventName) => {
  window.gtag("event", eventName.toLowerCase(), {
    event_category: "click",
    event_path: window.location.href,
    time: new Date(),
  });
};
