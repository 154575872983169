import { configureStore } from "@reduxjs/toolkit";
import appReducer, { storeBookmark } from "./reducer/app";
import databaseReducer from "./reducer/database";

export const store = configureStore({
  reducer: {
    app: appReducer,
    database: databaseReducer,
  },
});

// store.dispatch(dbSignInAnonymously());
store.dispatch(storeBookmark());
