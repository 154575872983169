import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { menuToggle } from "../../store/reducer/app";
import { getCurrentLanguage } from "../../store/selector";
import { BottomNavigationItems } from "../utils";

const BottomNavigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const menuToggleHandler = () => {
    dispatch(menuToggle(true));
  };

  return (
    <>
      <div className="un-bottom-navigation filter-blur">
        <div className="em_body_navigation border-0 -active-links">
          {BottomNavigationItems.map(({ path, defaultIcon, activeIcon }) => (
            <React.Fragment key={path}>
              <div className="item_link">
                {path === "BUTTON" ? (
                  <div onClick={menuToggleHandler}>{defaultIcon}</div>
                ) : (
                  <>
                    <Link to={`/${getCurrentLanguage()}${path}`} 
                    className={`btn btn_navLink ${location.pathname === `/${getCurrentLanguage()}${path}` && "active"}`} 
                    onClick={() => trackEvent('BottomMenu_' + path)}>
                      <div className="icon_current">{defaultIcon}</div>
                      <div className="icon_active">{activeIcon}</div>
                    </Link>
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
export default BottomNavigation;
