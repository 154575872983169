import { IoCloseCircle } from "react-icons/io5";
import { debounce } from "../utils";

const InputBox = ({ onChangeText, inputRef, isLoading, value, clearInput, data }) => {
  const inputProcess = debounce((e) => onChangeText(e));
  const TRANS = window.TRANS; 

  return (
    <>
      <div className="search__input__container">
        <input type="text" className="search__input__box" autoComplete="off" placeholder={TRANS.SearchPlaceholder} onChange={inputProcess} ref={inputRef} />
        <div className="search__input__state">
          {!isLoading && value?.length > 0 && (
            <div className="search__input__clear" onClick={clearInput}>
              <IoCloseCircle />
            </div>
          )}
          {isLoading && <div className="search__input__loader"></div>}
        </div>
      </div>
    </>
  );
};
export default InputBox;
