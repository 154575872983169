import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "../../analytics";
import { changeDisplayType } from "../../store/reducer/app";
import { CategoryBlock } from "../Carousels/CategoryCarousel";
import { Layout } from "../StyledComponents/index";
import { categories } from "../utils";

const CategoryDisplay = () => {
  const dispatch = useDispatch();
  const { displayType } = useSelector((state) => state.app);
  const CategoryDisplay = (type) => {
    dispatch(changeDisplayType(type));
  };

  const categoryViewToggle = (type) => {
    CategoryDisplay(type);
    trackEvent('Category_Toggle_Switch_' + type);
  };

  return (
    <>
      <div className="padding-10 grid__tabs__toggle d-none">
        <ul className="nav nav-pills nav-pilled-tab border" id="pills-tab" role="tablist">
          <li className={`${displayType === "circle" && "active"} nav-item`} role="presentation">
            <button
              className={`${displayType === "circle" && "active"} nav-link`}
              data-bs-toggle="pill"
              data-bs-target="#pills-grid"
              type="button"
              role="tab"
              onClick={() => categoryViewToggle("circle")}
            >
              Circular
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`${displayType === "grid" && "active"} nav-link`}
              id="pills-Owner-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Owner"
              type="button"
              role="tab"
              onClick={() => categoryViewToggle("grid")}
            >
              Grid
            </button>
          </li>
        </ul>
      </div>
      <Layout>
        <div className="category__grid block__grid__wrapper">
          <CategoryBlock source={categories} id="category" />
        </div>
      </Layout>
    </>
  );
};

export default CategoryDisplay;
