import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "../analytics";
import BookmarkCard from "../components/Cards/BookmarkCard";
import { Layout } from "../components/StyledComponents";
import Page from "../routes/PageLayout";
import { setUserFavourites } from "../store/reducer/app";

const Bookmarks = () => {
  const dispatch = useDispatch();
  const { favourites } = useSelector((state) => state.app);
  const { isEnglish } = useSelector((state) => state.database);

  const deleteBookmarkHandler = (usesData) => {
    dispatch(setUserFavourites(usesData));
    trackEvent('favourites'+ usesData.Uses_ID);
  };

  const TRANS = window.TRANS;
  return (
    <Page title={TRANS.Bookmarks} isFooter={false}>
      <div className="menu__spacer"></div>

        <Layout>
          {favourites.length > 0 && (
            <>
              <div className="my-3">
                <div className="d-flex justify-content-between">
                  <h2>{TRANS.YourBookmarks}</h2>
                  <h4 className="results__count">{favourites.length}</h4>
                </div>
                <BookmarkCard data={favourites} click={deleteBookmarkHandler} isEnglish={isEnglish}/>
              </div>
            </>
          )}
          {favourites.length === 0 && (
            <div className="mt-5 pt-5 text-center">
              <img src="/assets/public/no-data.svg" alt="Search Results" className="img-fluid" />
              <h2 className="my-5 text-muted">{TRANS.No_bookmark}</h2>
              <p className="text-muted">---</p>
            </div>
          )}
        </Layout>
    </Page>
  );
};
export default Bookmarks;
