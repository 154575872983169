import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { trackEvent } from "../../analytics";

const Suggestion = ({ searchItems = null, search, title, canDelete = false, onDelete }) => {
  const suggestionClickHandler = (searchQuery) => {
    search(searchQuery?.query)
    trackEvent('Search_Suggestion' + searchQuery?.query);
  }
  return (
    <>
      {searchItems && (
        <div className="suggested__search__container">
          <div className="suggested__search__title">
            <h4>{title}</h4>
            {canDelete && (
              <div className="suggested__search__delete" onClick={onDelete}>
                <AiFillDelete />
              </div>
            )}
          </div>
          <ul className="suggested__search__capsule">
            {searchItems?.map(({ query, id }) => (
              <li key={id} onClick={() => suggestionClickHandler({ query })}>
                {query}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
export default Suggestion;
