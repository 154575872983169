import React from "react";
import {
  RiHeart3Fill,
  RiHeart3Line,
  RiHome5Fill,
  RiHome5Line,
  RiMenuLine,
  RiQuestionFill,
  RiQuestionLine,
  RiSearch2Fill,
  RiSearch2Line,
  RiStoreFill,
  RiStoreLine,
} from "react-icons/ri";
import AnH_Logo from '../assets/anh-logo.png';
import Baby from "../assets/category-icons/baby-care.png";
import Bathroom from "../assets/category-icons/bathroom-uses.png";
import Kids from "../assets/category-icons/fun-for-kids.png";
import Household from "../assets/category-icons/household-uses.png";
import Kitchen from "../assets/category-icons/kitchen-uses.png";
import Outdoor from "../assets/category-icons/outdoor-uses.png";
import PersonalCare from "../assets/category-icons/personal-care.png";
import Petcare from "../assets/category-icons/pet-care.png";
import Contest_1 from "../assets/contest_alert/contest_1.jpg";
import Contest_2 from "../assets/contest_alert/contest_2.jpg";
import Contest_3 from "../assets/contest_alert/contest_3.jpg";
import Banner_1 from "../assets/hero_banner/banner-1.jpg";
import Banner_2 from "../assets/hero_banner/banner-2.jpg";
import Banner_3 from "../assets/hero_banner/banner-3.jpg";
import { TRANS_AR } from "../lang/AR";
import { TRANS_EN } from "../lang/EN";
import { EN_LANG, Uses } from "../routes/PathConfig";
import { getCurrentLanguage } from "../store/selector";

const isEnglish = getCurrentLanguage() === EN_LANG;

export const DEFAULT_THEME = "light";
export const DARK_THEME = "dark";
export const Circle_Menu_Icons = [
  { id: Uses.FunForKids, cords: "679,338,827,494" },
  { id: Uses.KitchenUses, cords: "577,578,730,737" },
  { id: Uses.PetCare, cords: "338,677,487,827" },
  { id: Uses.HouseHoldUses, cords: "93,579,251,732" },
  { id: Uses.Outdoors, cords: "0,341,149,490" },
  { id: Uses.PersonalCare, cords: "97,93,253,250" },
  { id: Uses.BathroomUses, cords: "491,151,338,0" },
  { id: Uses.BabyCare, cords: "572,101,731,255" },
];
export const heroBanners = [
  { id: "87ff78be", imgSrc: Banner_1 },
  { id: "87ff7a76", imgSrc: Banner_2 },
  { id: "87ff7b70", imgSrc: Banner_3 },
];
export const EXTERNAL = "external";

export const BottomNavigationItems = [
  {
    path: "/",
    defaultIcon: React.createElement(RiHome5Line),
    activeIcon: React.createElement(RiHome5Fill),
  },
  {
    path: "/search",
    defaultIcon: React.createElement(RiSearch2Line),
    activeIcon: React.createElement(RiSearch2Fill),
  },
  {
    path: "BUTTON",
    defaultIcon: React.createElement(() => (
      <button className="btn btn_navLink without_active">
        <div className="btn btnCircle_default text-white">
          <RiMenuLine />
        </div>
      </button>
    )),
  },
  {
    path: "/bookmarks",
    defaultIcon: React.createElement(RiHeart3Line),
    activeIcon: React.createElement(RiHeart3Fill),
  },

  {
    path: "/products",
    defaultIcon: React.createElement('img', {src : AnH_Logo, className : 'img__icon'}, null),
    activeIcon: React.createElement('img', {src : AnH_Logo, className : 'img__icon'}, null),
  },
];

export const SIDE_DRAWER_DEFAULT = [
  {
    title: isEnglish ? TRANS_EN.Home : TRANS_AR.Home,
    path: "/",
    defaultIcon: React.createElement(RiHome5Line),
    activeIcon: React.createElement(RiHome5Fill),
  },
  {
    title:  isEnglish ? TRANS_EN.Bookmarks : TRANS_AR.Bookmarks,
    path: "/bookmarks",
    defaultIcon: React.createElement(RiHeart3Line),
    activeIcon: React.createElement(RiHeart3Fill),
  },
  {
    title: isEnglish ? TRANS_EN.Products : TRANS_AR.Products,
    path: "/products",
    defaultIcon: React.createElement('img', {src : AnH_Logo, className : 'sidenav__img__icon'}, null),
    activeIcon: React.createElement('img', {src : AnH_Logo, className : 'sidenav__img__icon'}, null),
  },
];
export const SIDE_DRAWER_EXTRAS = [
  {
    title: isEnglish ? TRANS_EN.Shop : TRANS_AR.Shop,
    linkType: EXTERNAL,
    path: "https://shop.armandhammerarabia.com/",
    defaultIcon: React.createElement(RiStoreLine),
    activeIcon: React.createElement(RiStoreFill),
  },
  {
    title: isEnglish ? TRANS_EN.HelpCenter : TRANS_AR.HelpCenter,
    path: "/help",
    defaultIcon: React.createElement(RiQuestionLine),
    activeIcon: React.createElement(RiQuestionFill),
  },
  {
    title: isEnglish ? TRANS_EN.AboutUs : TRANS_AR.AboutUs,
    linkType: EXTERNAL,
    path: "https://www.armandhammer.com/en/about-us",
    defaultIcon: React.createElement(() => <img src="/assets/public/anh-logo.png" alt="cover" className="logo__icon img-fluid" />),
    activeIcon: React.createElement(() => <img src="/assets/public/anh-logo.png" alt="cover" className="logo__icon img-fluid" />),
  },
];

export const categories = [
  { name: isEnglish ? TRANS_EN.household_uses : TRANS_AR.household_uses , iconSrc: Household, slug: "household-uses" , description: isEnglish ? TRANS_EN.housedesc : TRANS_AR.housedesc  },
  { name: isEnglish ? TRANS_EN.bathroom_uses : TRANS_AR.bathroom_uses , iconSrc: Bathroom, slug: "bathroom-uses", description: isEnglish ? TRANS_EN.bathroomdesc : TRANS_AR.bathroomdesc  },
  { name: isEnglish ? TRANS_EN.kitchen_uses : TRANS_AR.kitchen_uses , iconSrc: Kitchen, slug: "kitchen-uses", description: isEnglish ? TRANS_EN.kitchendesc : TRANS_AR.kitchendesc },
  { name: isEnglish ? TRANS_EN.personal_care : TRANS_AR.personal_care , iconSrc: PersonalCare, slug: "personal-care", description: isEnglish ? TRANS_EN.personaldesc : TRANS_AR.personaldesc },
  { name: isEnglish ? TRANS_EN.outdoor_uses : TRANS_AR.outdoor_uses , iconSrc: Outdoor, slug: "outdoor-uses", description: isEnglish ? TRANS_EN.teeracedesc : TRANS_AR.teeracedesc},
  {
    name: isEnglish ? TRANS_EN.pet_care : TRANS_AR.pet_care ,
    iconSrc: Petcare,
    slug: "pet-care", 
    description: isEnglish ? TRANS_EN.petdesc : TRANS_AR.petdesc
  },
  { name: isEnglish ? TRANS_EN.baby_care : TRANS_AR.baby_care , iconSrc: Baby, slug: "baby-care", description: isEnglish ? TRANS_EN.babydesc : TRANS_AR.babydesc },
  { name: isEnglish ? TRANS_EN.fun_for_kids : TRANS_AR.fun_for_kids , iconSrc: Kids, slug: "fun-for-kids", description: isEnglish ? TRANS_EN.kidsdesc : TRANS_AR.kidsdesc },
];

export const contestBanners = [
  {
    id: "77ff78be",
    imgSrc: Contest_1,
    link: "https://www.facebook.com/photo/?fbid=1310489859418538",
  },
  {
    id: "77ff7b70",
    imgSrc: Contest_3,
    link: "https://www.facebook.com/photo/?fbid=399984558803079",
  },
  {
    id: "77ff7a76",
    imgSrc: Contest_2,
    link: "https://www.facebook.com/photo.php?fbid=405741724894029",
  },
];

export const socialLinks = {
  facebook: "https://www.facebook.com/armandhammerarabia/",
  instagram: "https://www.instagram.com/armandhammerarabia/",
  youtube: "https://www.youtube.com/channel/UC__8RTALWd5wfWf-ddMj-EQ/videos",
};

export const debounce = (_function, timeout = 800) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      _function.apply(this, args);
    }, timeout);
  };
};

export const ALL_CATEGORIES = [
  "baby-care",
  "bathroom-uses",
  "fun-for-kids",
  "household-uses",
  "kitchen-uses",
  "outdoor-uses",
  "personal-care",
  "pet-care",
];

export const getRandomDataID = () => {
  const data = JSON.parse(sessionStorage.getItem("data"))?.BakingSodaContent;
  const categories = ALL_CATEGORIES;
  const randomIndex = (array) => Math.floor(Math.random() * array.length);
  const category = categories[randomIndex(categories)];
  const subCategory = Object.keys(data[category]?.UsesData);
  return { category, subCategory: subCategory[randomIndex(subCategory)] };
};

export const searchSuggestions = [
  { id: "cdd017d1", query: "Baking" },
  { id: "cdd017d2", query: "Laundry" },
  { id: "cdd017d3", query: "Deodorize" },
  { id: "cdd017d4", query: "Kids" },
  { id: "cdd017d5", query: "Camping" },
  { id: "cdd017d6", query: "Kitchen" },
];

export const flattenData = (data) => {
  const mergedData = [];
  data?.BakingSodaContent && Object.entries(data?.BakingSodaContent).map(([_, value]) => {
    const __ = Object.entries(value?.UsesData);
    const reducer = __.reduce((acc, [id, usesData]) => {
      acc.push({ id, ...usesData });
      return acc;
    }, []);
    return mergedData.push(...reducer);
  });
  return mergedData;
};

export const CATEGORY = "category";
export const SUBCATEGORY = "subCategory";
export const USES_TITLE = "usesTitle";
export const TITLE = "Title";
export const PREFS = "userPrefs";

export const USER_INITIAL_PREFS = {
  EN: { pageViews: { [CATEGORY]: [], [SUBCATEGORY]: [] }, pageLikes: { [CATEGORY]: [], [SUBCATEGORY]: [] }, bookmarks: { ID: [] } },
  AR: { pageViews: { [CATEGORY]: [], [SUBCATEGORY]: [] }, pageLikes: { [CATEGORY]: [], [SUBCATEGORY]: [] }, bookmarks: { ID: [] } },
};

export const LANGUAGE = "locale";


export const handleNativeShare = async (title, url) => {
  if (window.navigator.share && title && url) {
    try {
      await navigator.share({ title, url });
    } catch (err) {
      console.error(err);
    }
  } else {
    alert("Not Supported!, Please update your browser");
  }
};

export const formatCount = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const FAQ = [
  {
    id: "faq__74__4",
    title: isEnglish ? TRANS_EN.faq1 : TRANS_AR.faq1,
    desc: isEnglish ? TRANS_EN.faq1desc : TRANS_AR.faq1desc ,
  },
  {
    id: "faq__74__5",
    title:  isEnglish ? TRANS_EN.faq2 : TRANS_AR.faq2,
    desc: isEnglish ? TRANS_EN.faq2desc : TRANS_AR.faq2desc,
  },
  {
    id: "faq__74__6",
    title: isEnglish ? TRANS_EN.faq3: TRANS_AR.faq3,
    desc: isEnglish ? TRANS_EN.faq3desc : TRANS_AR.faq3desc,
  },
  {
    id: "faq__74__7",
    title: isEnglish ? TRANS_EN.faq4: TRANS_AR.faq4,
    desc: isEnglish ? TRANS_EN.faq4desc : TRANS_AR.faq4desc,
  },
  {
    id: "faq__74__8",
    title: isEnglish ? TRANS_EN.faq5: TRANS_AR.faq5,
    desc: isEnglish ? TRANS_EN.faq5desc : TRANS_AR.faq5desc,
  },
  {
    id: "faq__74__9",
    title: isEnglish ? TRANS_EN.faq6: TRANS_AR.faq6,
    desc: isEnglish ? TRANS_EN.faq6desc : TRANS_AR.faq6desc,
  },
  {
    id: "faq__74__a1",
    title: isEnglish ? TRANS_EN.faq7: TRANS_AR.faq7,
    desc: isEnglish ? TRANS_EN.faq7desc : TRANS_AR.faq7desc,
  },
  {
    id: "faq__74__a2",
    title: isEnglish ? TRANS_EN.faq8: TRANS_AR.faq8,
    desc: isEnglish ? TRANS_EN.faq8desc : TRANS_AR.faq8desc,
  },
  {
    id: "faq__74__a3",
    title: isEnglish ? TRANS_EN.faq9: TRANS_AR.faq9,
    desc: isEnglish ? TRANS_EN.faq9desc : TRANS_AR.faq9desc,
  },
];
