import { motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { trackEvent } from "../../analytics";
import { storeBookmark } from "../../store/reducer/app";
import { updateLanguage } from "../../store/reducer/database";
import { getCurrentLanguage } from "../../store/selector";
import { LANGUAGE } from "../utils";

const LanguageModal = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const language = getCurrentLanguage().toUpperCase();

  const closeModalWindow = (language) => {
    if (language === "EN" || language === "AR") {
      localStorage.setItem(LANGUAGE, language);
      dispatch(updateLanguage(language));
      dispatch(storeBookmark());
      trackEvent('Language_Switch' + language);
      setTimeout(() => { window.location.reload();}, 500);
    }
    setActive(false);
  };

  return (
    <>
      {active &&
        ReactDOM.createPortal(
          <div className={`lang__modal__wrapper ${active ? "show" : "hide"}`}>
            <div className="lang__modal__overlay" onClick={closeModalWindow}></div>

            <div className="lang__modal__container">
              <div className="lang__modal__close" onClick={closeModalWindow}>
                <IoCloseOutline />
              </div>
              <div className="lang__modal__body">
                <div className="lang__modal__logo">
                  <img src="/assets/public/anh-logo.png" alt="logo" />
                </div>
                <div className="lang__modal__context">
                  <h4>Please select your preferred available language.</h4>
                </div>
                <div className="lang__modal__country">
                  <motion.div
                    className={`lang__modal__select ${language === "EN" && "active"}`}
                    whileTap={{ scale: 0.8 }}
                    onAnimationComplete={() => closeModalWindow("EN")}
                  >
                    <div className="lang__modal__flag">
                      <img src="/assets/public/flag-us.png" alt="flag" />
                    </div>
                    <p className="lang__country__name">English</p>
                  </motion.div>
                  <motion.div
                    className={`lang__modal__select ${language === "AR" && "active"}`}
                    whileTap={{ scale: 0.8 }}
                    onAnimationComplete={() => closeModalWindow("AR")}
                  >
                    <div className="lang__modal__flag">
                      <img src="/assets/public/flag-ar.png" alt="flag" />
                    </div>
                    <p className="lang__country__name">Arabic</p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>,
          document.getElementById("language__modal")
        )}
    </>
  );
};
export default LanguageModal;
