import React from "react";
import { categories } from "../utils";
import "./des.card.scss";

const DesCard = ({ isEnglish, usesDescription, usesList, usesIcon = false }) => {
  const { iconSrc } = usesIcon && categories.find((_) => _.slug === usesIcon);

  return (
    <>
      <div className="des__card__container shadow-sm">
        <div className="des__card__heading__wrapper">
          {usesIcon && <img src={iconSrc} alt={usesIcon} />}
          <h3>{usesDescription}</h3>
        </div>
        <ul className="des__card__description">
          {usesList.map((content, i) => (
            <li key={i}>{isEnglish ? content.Title : content.Title_Ar}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default DesCard;
