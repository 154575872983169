import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DesCard from "../components/Cards/DesCard";
import MediaCard from "../components/Cards/MediaCard";
import SubCategorySkeleton from "../components/Skeleton/SubCategorySkeleton";
import { Layout } from "../components/StyledComponents/index";
import PageNotFound from "../pages/PageNotFound";
import Page from "../routes/PageLayout";

const SubCategory = () => {
  const { product } = useParams();
  const { data, isDataLoading } = useSelector((state) => state.database);

  const productData = data?.Products?.[product];
  if (data?.BakingSodaContent && !productData) {
    return <PageNotFound />;
  }

  return (
    <Page className="sub__category__page" title={productData?.title || "Sub Category"}>
      {productData && !isDataLoading ? (
        <>
          <Layout className="menu__spacer">
            <MediaCard
              isImage={true}
              mediaSource={productData?.mediaSource}
              title={productData?.title}
              link={productData?.link}
              isProductPage={true}
            />
            <DesCard usesDescription={productData?.description} usesList={productData?.productDescription} />
          </Layout>
        </>
      ) : (
        <Layout className="menu__spacer">
          <SubCategorySkeleton />
        </Layout>
      )}
    </Page>
  );
};

export default SubCategory;
