import { motion } from "framer-motion";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { trackEvent } from "../../analytics";
import "./list.card.scss";

const ListCard = ({ link, title, path, index }) => {
  return (
    <>
      <Link to={`/${path}/${link}`} className="list__card__wrapper"
      onClick={() => trackEvent(`SubCategory_${title}_${link}`)}>
        <motion.div className="list__card__container" whileTap={{ scale: 0.97 }}>
          <div className="list__card__index">{index + 1}</div>
          <div className="list__card__title">
            <h5>{title}</h5>
          </div>
          <div className="list__card__arrow">
            <IoIosArrowForward />
          </div>
        </motion.div>
      </Link>
    </>
  );
};

export default ListCard;
