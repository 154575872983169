import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "../analytics";
import Carousel from "../components/Carousels/Carousel";
import HeroCarousel from "../components/Carousels/HeroCarousel";
import Categories from "../components/CategoryDisplay";
import HeroBlade from "../components/Hero";
import { FeelingLucky, LayoutSection } from "../components/Misc";
import { FeelingLuckyModal } from "../components/Modal";
import { Layout } from "../components/StyledComponents/index";
import { getRandomDataID } from "../components/utils";
import Page from "../routes/PageLayout";
import { PRODUCTS } from "../routes/PathConfig";
import { themeSelector } from "../store/reducer/app";

function HomePage() {
  const dispatch = useDispatch();
  const { language, data } = useSelector((state) => state.database);
  const [modalState, setModalState] = useState({ state: false });
  const themeHandler = () => {
    dispatch(themeSelector());
  };

  const feelingLuckyHandler = useCallback(() => {
    const data = getRandomDataID(language);
    setModalState({ state: true, data });
    trackEvent('Feeling_Lucky_'+ data?.category);
  }, [language]);

  return (
    <Page title="ARM & HAMMER™">
      <HeroBlade />
      <Categories />
      <Layout>
        <FeelingLucky click={feelingLuckyHandler} />
        <HeroCarousel id="home__hero_carousel" indicators={true} />
        <button onClick={themeHandler} className="d-none">
          Dark mode
        </button>
      </Layout>

      <LayoutSection title="Products" link={PRODUCTS}>
        <Carousel source={data?.Products} id="home__products"/>
      </LayoutSection>

      {/* <LayoutSection title="Recommendations" link="/random">
        <Carousel source={heroBanners} id="home__recommendation" />
      </LayoutSection> */}
      {modalState.state && (
        <FeelingLuckyModal modalState={modalState} setModalState={setModalState} />
      )}
    </Page>
  );
}

export default HomePage;
