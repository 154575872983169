export const TRANS_AR = {
    Language: 'العربية',
    Follow_Us : "تابعنا",
    Copy_right_content:"الذراع والمطرقة. كل الحقوق محفوظة.",
    Nav_back : "خلف",
    Explore_Use : "اكتشف 100 استخدام",
    Shop_now : "تسوق الآن ",
    List_products : "قائمة بجميع المنتجات",
    No_bookmark : "لم يتم العثور على الإشارات المرجعية!",
    Freequent: " أسئلة متكررة" ,
    Freequent_desc : " إذا كان لديك سؤال، فمن المحتمل أن يكون شخص آخر قد طرح نفس السؤال. ولهذا السبب قمنا بتجميع الأسئلة الأكثر شيوعًا بشكل صحيح  هنا. ما عليك سوى اختيار الفئة والعثور على إجابتك. ",
    email_support : " دعم البريد الإلكتروني",
    Home : "بيت",
    Bookmarks : "إشارات مرجعية",
    Products : "منتجات",
    Shop : "محل",
    HelpCenter : "مركز المساعدة",
    AboutUs : "معلومات عنا",
    others : "آخر",
    faq1 : "ما هي مدة صلاحية صودا الخبز؟",
    faq1desc : "مدة الصلاحية ثلاث سنوات. تاريخ انتهاء الصلاحية موجود أسفل العلبة والحقيبة.",
    faq4desc :"صودا الخبز هي بيكربونات الصوديوم النقية بنسبة 100%. مسحوق الخبز عبارة عن خليط من صودا الخبز (عادة حوالي 28-30%) ومكونات حمضية مختلفة (مفردة أو مجتمعة). يتم استخدام كلاهما كعوامل تخمير في المخبوزات، إذ تتفاعل صودا الخبز مع الحمض، مما يؤدي إلى إطلاق غاز ثاني أكسيد الكربون ويؤدي إلى ارتفاع العجين. ومع ذلك، يتم استخدامها في حالات مختلفة وغير قابلة للتبديل. تُستخدم صودا الخبز عادةً في الوصفة التي تحتوي على مكونات حمضية (كريمة التارتار واللبن وما إلى ذلك) والتي تتفاعل مع الصودا وتطلق ثاني أكسيد الكربون. يستخدم مسحوق الخبز، الذي يحتوي على واحد أو أكثر من المكونات الحمضية لتسهيل التفاعل، في الوصفات التي لا تحتوي على مكونات حمضية.",
    faq5desc : "في حين أن صودا الخبز المستخدمة في عبوة الخلط هي صودا الخبز ARM & HAMMER™ نقية بنسبة 100%، إلا أنها ذات حبيبات مختلفة عما هو موجود في الصندوق الأصفر. هذا التحبيب المختلف يجعل صودا الخبز أكثر تدفقًا بحرية وأقل عرضة للتكتل والتكتل، خاصة في ظروف الرطوبة العالية مثل تنظيف المطبخ.",
    faq6desc : "صودا الخبز مصنوعة من بيكربونات الصوديوم بنسبة 100%. صودا الغسيل الفائقة مصنوعة من كربونات الصوديوم بنسبة 100%. في حين أنها تبدو متشابهة، فهي ليست هي نفسها. يمكن استخدام كلا المنتجين لتحسين أداء الغسيل السائل للحصول على ملابس أكثر نظافة ونضارة. يمكن أيضًا استخدام كلا المنتجين للتنظيف في جميع أنحاء المنزل. يمكن استخدام صودا الخبز في الخبز، كمعجون أسنان وكمضاد للحموضة، لكن لا يمكن استخدام صودا الغسيل الفائقة. لا ينبغي أبدًا تناول صودا الغسيل الفائقة. تأكد من التحقق من عبوة المنتج لمعرفة الاستخدامات والتوصيات المحددة.",
    faq7desc : "توفر صودا الخبز إجراءً كاشطًا خفيفًا للمساعدة في تلميع الأسنان وتنظيفها وإزالة الروائح الكريهة منها. إنه الاختيار الطبيعي للمنتجات الشخصية عالية الفعالية مثل معاجين الأسنان وغسول الفم والغرغرة.",
    faq8desc : "تحمي صودا الخبز بشكل مؤقت وتساعد على تخفيف تهيج الجلد البسيط والحكة عن طريق تحييد السموم والمهيجات على سطح الجلد. اتبع توجيهات الحزمة المدرجة تحت حقائق المخدرات لحماية الجلد.",
    faq9desc : "تحتوي صودا الخبز المخصصة للأسنان على حجم جسيمات خاص لتوفير التنظيف الأمثل مع تآكل منخفض.",
    faq7 : "عندما تستخدم كمعجون أسنان، ما مدى كاشطة صودا الخبز؟",    
    faq2 : "هل يمكنني استخدام صودا الخبز وClear Balance لحمام السباحة الخاص بي؟",
    faq2desc : "نعم، نوصي باستخدام صودا الخبز في البداية لرفع درجة الحموضة والقلوية، ثم اتبع ذلك بالاستخدام المنتظم لـ Clear Balance للحفاظ على المستويات.",
    faq3 : "كم مرة يجب تغيير علبة صودا الخبز في الثلاجة و/أو الفريزر؟",
    faq3desc : "نوصي بتغيير الصندوق الموجود في ثلاجتك/فريزرك مرة واحدة شهريًا للحصول على نضارة مثالية.",
    faq4 : "ما هو الفرق بين صودا الخبز ومسحوق الخبز؟",
    faq5 : "كيف يختلف الصندوق عن الشاكر؟",
     faq6 : "كيف تختلف صودا الخبز عن صودا الغسيل الفائقة؟",
     faq8 : "كيف تساعد صودا الخبز في تهدئة اللبلاب السام ولسعات النحل؟",
    faq9 : "ما هي صودا الخبز الصف الأسنان؟",
    Help : "ساعد لدعم",
    household_uses : "الاستخدامات المنزلية",
    bathroom_uses : "استخدامات الحمام",
    kitchen_uses : "استخدامات المطبخ",
    personal_care : "رعاية شخصية",
    outdoor_uses : "تراس وجراج",
    pet_care : "رعاية الحيوانات الاليفة",
    baby_care : "عناية الطفل",
    fun_for_kids : "متعة للأطفال",
    housedesc : "(مغسلة، سجاد..)",
    bathroomdesc : "(المجاري والبلاط..)",
    kitchendesc : "(ثلاجة، حوض..)",
    personaldesc : "(الوجه، اليدين..)",
    teeracedesc : "(سيارة، حديقة..)",
    petdesc : "(القط الكلب..)",
    babydesc : "(اللعب والزجاجات..)",
    kidsdesc : "(الطين، الألوان..)",
    feeling : "أشعر أنني محظوظ!",
    feelingdesc : "عرض أي استخدام عشوائي من القائمة!",
    Next : 'التالي',
    Prev: 'السابق',
    ViewAll: 'استكشاف جميع المنتجات',
    Search : "يبحث",
    SearchPlaceholder : "...البحث عن الاستخدامات",
    RecentSearch : "البحث الأخير",
    SearchResults : "نتائج البحث",
    LearnMore: 'يتعلم أكثر',
    YourBookmarks: 'الإشارات المرجعية الخاصة بك',
    ThisPageWillOpenInAnother : 'ستفتح هذه الصفحة تطبيقًا آخر.',
    Cancel: 'يلغي',
    Open: 'يفتح'
}





