import React from "react";
import "./skeleton.scss";
import ContentLoader from "react-content-loader";

const SubCategorySkeleton = (props) => {
  return (
    <>
      <div className="sub-container shadow-sm">
        <ContentLoader speed={2} width={540} height={325} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
          <rect x="0" y="0" rx="9" ry="9" width="100%" height="240" />
          <rect x="15" y="260" rx="9" ry="9" width="80%" height="20" />
          <rect x="15" y="300" rx="9" ry="9" width="50%" height="20" />
        </ContentLoader>
      </div>
      <div className="sub-container shadow-sm">
        <ContentLoader speed={2} width={540} height={85} backgroundColor="#e8e8e8" foregroundColor="#dddddd" className="border-bottom" {...props}>
          <circle cx="37" cy="42" r="22" />
          <rect x="73" y="22" rx="9" ry="9" width="75%" height="15" />
          <rect x="73" y="47" rx="9" ry="9" width="60%" height="15" />
        </ContentLoader>
        <ContentLoader speed={2} width={540} height={140} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
          <circle cx="23" cy="30" r="8" />
          <rect x="45" y="23" rx="9" ry="9" width="80%" height="15" />
          <rect x="45" y="48" rx="9" ry="9" width="60%" height="15" />
          <circle cx="23" cy="94" r="8" />
          <rect x="45" y="87" rx="9" ry="9" width="80%" height="15" />
          <rect x="45" y="112" rx="9" ry="9" width="60%" height="15" />
        </ContentLoader>
      </div>
    </>
  );
};

export default SubCategorySkeleton;
