import React from "react";

const RippleAnimation = ({ className }) => {
  return (
    <>
      <svg
        id="e4uxFnZsKq91"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 900 900"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        className={className}
      >
        <g transform="translate(36.383184 37.183724)">
          <g id="e4uxFnZsKq93_ts" transform="translate(172.408293,172.362042) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq93"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq94_ts" transform="translate(414,72.386995) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq94"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq95_ts" transform="translate(654.825308,174.362042) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq95"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq96_ts" transform="translate(754.654392,413) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq96"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq97_ts" transform="translate(654.825308,654.416381) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq97"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq98_ts" transform="translate(413,753.245539) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq98"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq99_ts" transform="translate(172.408293,654.416381) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq99"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
          <g id="e4uxFnZsKq910_ts" transform="translate(72.579209,413) scale(0.608126,0.608126)">
            <ellipse
              id="e4uxFnZsKq910"
              rx="74.098884"
              ry="74.098884"
              transform="translate(0,0)"
              opacity="0"
              fill="none"
              fillOpacity="0"
              stroke="#d02530"
              strokeWidth="2"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
export default RippleAnimation;
