import { motion } from "framer-motion";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { getCurrentLanguage } from "../../store/selector";

const BookmarkCard = ({ data, click, isEnglish }) => {
  return (
    <>
      {data.length > 0 &&
        data.map((it) => (
          <motion.div whileTap={{ scale: 0.97 }} key={it.Uses_ID}>
            <div className="des__card__container shadow-sm">
              <ul className="des__card__description mb-0">
                <li className="d-flex bookmark__title__card">
                  <Link to={`/${getCurrentLanguage()}/explore/${it.Category_ID}/${it.Uses_ID}`} className="anchor__reset" onClick={() => trackEvent(`favourites_${it.Title}_CategoryID=${it.Category_ID}`)}>
                    <span>{isEnglish ? it.Title : it.Title_Ar}</span>
                  </Link>
                  <AiFillDelete onClick={() => click(it)} />
                </li>
                <Link to={`/${getCurrentLanguage()}/explore/${it.Category_ID}/${it.Uses_ID}`} className="anchor__reset" onClick={() => trackEvent(`favourites_${it.Title}_CategoryID=${it.Category_ID}`)}>
                  <div className="d-flex justify-content-between">
                    <div className="tag tag__green category__tag">{it.Category_ID.toUpperCase()}</div>
                    <IoIosArrowForward className="arrow__ion__ico"/>
                  </div>
                </Link>
              </ul>
            </div>
          </motion.div>
        ))}
      <hr className="mt-4 mb-5 mx-5" />
    </>
  );
};
export default BookmarkCard;
