import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { menuToggle } from "../../store/reducer/app";
import { getCurrentLanguage } from "../../store/selector";
import { DropSheetModal } from "../Modal";
import { ALL_CATEGORIES, EXTERNAL, SIDE_DRAWER_DEFAULT, SIDE_DRAWER_EXTRAS } from "../utils";

const Drawer = () => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.app.menuState);
  const [showModal, setShowModal] = useState({ state: false, link: null });

  const menuToggleHandler = () => {
    dispatch(menuToggle(!menu));
    trackEvent('Left_Menu_inView_' + menu);
  };

  const activeMenuState = (path) => {
    if (ALL_CATEGORIES.includes(window.location.pathname.split("/")[1])) {
      return path === "/categories" && "active";
    } else {
      return window.location.pathname === `/${getCurrentLanguage()}${path}` && "active";
    }
  };

  const externalLinkModal = (navigate = false, link) => {
    trackEvent('Left_Menu_'+ link);
    if (navigate && showModal.link) {
      window.open(showModal.link, "_blank").focus();
    }
    setShowModal({ state: !showModal.state, link });
  };
const TRANS = window.TRANS;
  return (
    <>
      <div className={`modal sidebarMenu -left fade d-block ${menu ? "show " : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-block pb-1">
              <div className="un-user-profile">
                <div className="image_user">
                  <picture>
                    <img src="/assets/public/anh-logo.png" alt="cover" />
                  </picture>
                </div>
                <div className="text-user">
                  <h3>ARM & HAMMER™</h3>
                  {/* <p>Good Morning!</p> */}
                </div>
              </div>

              <div className="btnClose border-0" onClick={menuToggleHandler}>
                <RiCloseLine />
              </div>
              <hr />
            </div>
            <div className="modal-body">
              <ul className="nav flex-column -active-links">
                {SIDE_DRAWER_DEFAULT?.map(({ title, path, defaultIcon, activeIcon }) => (
                  <React.Fragment key={title}>
                    <li className="nav-item" onClick={menuToggleHandler}>
                      <Link className={`nav-link ${activeMenuState(path)}`} to={`/${getCurrentLanguage()}${path}`} onClick={() => trackEvent('Left_Menu_Click_'+ path)}>
                        <div className="icon_current">{defaultIcon}</div>
                        <div className="icon_active">{activeIcon}</div>
                        <span className="title_link">{title}</span>
                        {activeMenuState(path) && (
                          <div className="badge-circle">
                            <span className="doted_item"></span>
                          </div>
                        )}
                      </Link>
                    </li>
                  </React.Fragment>
                ))}

                <label className="title__label"> {TRANS.others}</label>

                {SIDE_DRAWER_EXTRAS?.map(({ title, path, defaultIcon, activeIcon, linkType }) => (
                  <React.Fragment key={title}>
                    {linkType === EXTERNAL ? (
                      <li className="nav-item" onClick={menuToggleHandler}>
                        <div className={`nav-link ${activeMenuState(path)}`} onClick={() => externalLinkModal(null, path)}>
                          <div className="icon_current">{defaultIcon}</div>
                          <div className="icon_active">{activeIcon}</div>
                          <span className="title_link">{title}</span>
                          {activeMenuState(path) && (
                            <div className="badge-circle">
                              <span className="doted_item"></span>
                            </div>
                          )}
                        </div>
                      </li>
                    ) : (
                      <li className="nav-item" onClick={menuToggleHandler}>
                        <Link className={`nav-link ${activeMenuState(path)}`} to={`/${getCurrentLanguage()}${path}`}>
                          <div className="icon_current">{defaultIcon}</div>
                          <div className="icon_active">{activeIcon}</div>
                          <span className="title_link">{title}</span>
                          {activeMenuState(path) && (
                            <div className="badge-circle">
                              <span className="doted_item"></span>
                            </div>
                          )}
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
            {/* <div className="modal-footer">
              <div className="em_darkMode_menu">
                <label className="text" htmlFor="switchDark">
                  <h3>Dark Mode</h3>
                  <p>Try our beta release!</p>
                </label>
                <label className="switch_toggle toggle_lg theme-switch" htmlFor="switchDark">
                  <input type="checkbox" className="switchDarkMode theme-switch" id="switchDark" />
                  <span className="handle"></span>
                </label>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <DropSheetModal show={showModal.state} click={(state) => externalLinkModal(state)} />

      {menu && <div className="modal-backdrop fade show" onClick={menuToggleHandler}></div>}
    </>
  );
};
export default Drawer;
