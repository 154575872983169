import { AnimatePresence } from "framer-motion";
import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigationType } from "react-router-dom";
import Bookmarks from "../pages/Bookmark";
import Category from "../pages/Category";
import Help from "../pages/Help";
import HomePage from "../pages/HomePage";
import { MainHomePage } from '../pages/Index';
import PageNotFound from "../pages/PageNotFound";
import Product from "../pages/Product";
import Products from "../pages/Products";
import Search from "../pages/Search";
import SubCategory from "../pages/SubCategory";
import TestPage from "../pages/TestPage";
import { BOOKMARKS, CATEGORY, HELP, HOME, INDEX, PRODUCT, PRODUCTS, SEARCH, SUBCATEGORY, TEST_PATH, _404 } from "./PathConfig";

const PageRoutes = () => {
  const location = useLocation();
  const type = useNavigationType();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  
  return (
    <AnimatePresence exitBeforeEnter={true} initial={false} custom={{ action: type }}>
      <Routes location={location} key={location.pathname}>
        <Route path={INDEX} element={<MainHomePage />}></Route>
        <Route path={HOME} element={<HomePage />}></Route>
        <Route path={PRODUCTS} element={<Products />}></Route>
        <Route path={PRODUCT} element={<Product />}></Route>
        <Route path={BOOKMARKS} element={<Bookmarks />}></Route>
        <Route path={SEARCH} element={<Search />}></Route>
        <Route path={HELP} element={<Help />}></Route>
        <Route path={_404} element={<PageNotFound />}></Route>
        <Route path={TEST_PATH} element={<TestPage />}></Route>
        <Route path={CATEGORY} element={<Category />}></Route>
        <Route path={SUBCATEGORY} element={<SubCategory />}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default PageRoutes;
