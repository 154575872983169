const theme = {
  light: {
    bodyBackground: "#f1f1f7",
    baseFontFamily: "Open sans, serif",
    baseFontSize: "14px",
    baseLineHeight: "1.6rem",
    headingFontFamily: "Open sans, serif",
    headingFontSize: "14px",
    headingLineHeight: "1.6rem",
    primaryBgColor: "#2563EB",
    primaryTextColor: "#000",
    primaryBgColorHover: "#1147bb",
    primaryTextColorHover: "#fff",
    bannerWrapperBG: "transparent linear-gradient(169deg, #dfffff 0%, #8d9ff7 100%) 0% 0% no-repeat",
    bannerWrapperTextColor: "#fff",
    grey: "#94ebff",
    primary: "#2563EB",
    primaryDark: "#1147bb",
    white: "#fff",
    duration: ".25s ease",
  },
  dark: {
    bodyBackground: "#000",
    baseFontFamily: "Open sans, serif",
    baseFontSize: "14px",
    baseLineHeight: "1.6rem",
    headingFontFamily: "Open sans, serif",
    headingFontSize: "14px",
    headingLineHeight: "1.6rem",
    primaryBgColor: "#2563EB",
    primaryTextColor: "#fff",
    primaryBgColorHover: "#1147bb",
    primaryTextColorHover: "#fff",
    bannerWrapperBG: "transparent linear-gradient(169deg, #dfffff 0%, #8d9ff7 100%) 0% 0% no-repeat",
    bannerWrapperTextColor: "#fff",
    grey: "#8d8d8d",
    primary: "#2563EB",
    primaryDark: "#1147bb",
    white: "#fff",
    duration: ".25s ease",
  },
};

export default theme;
