import React from "react";
import ReactDOM from "react-dom";
import { trackEvent } from "../../analytics";

const DropSheetModal = ({ click, show }) => {
  const clickHandler = (state) => {
    click(state);
    trackEvent('DropSheet_External_Link_Message_Window');
  }
  
  const TRANS = window.TRANS;
  
  return (
    <>
      {ReactDOM.createPortal(
        <>
          <div className={`modal transition-bottom screenFull defaultModal fade ${show && "show"} d-block`} role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-0"></div>
                <div className="modal-body pt-0">
                  <div className="content-alert-actions">
                    <div className="margin-b-20">
                      <h2>{TRANS.ThisPageWillOpenInAnother}</h2>
                    </div>

                    <div className="action-links">
                      <button type="button" className="btn border color-text rounded-pill margin-r-20" onClick={() => clickHandler(false)}>
                        {TRANS.Cancel}
                      </button>
                      <button className="btn btn-primary rounded-pill" onClick={() => clickHandler(true)}>
                        {TRANS.Open}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <div className="env-pb"></div>
                </div>
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show" onClick={() => click(false)}></div>}
        </>,
        document.getElementById("dropSheet__modal")
      )}
    </>
  );
};
export default DropSheetModal;
