import { motion } from "framer-motion";
import React, { useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { IoIosShareAlt } from "react-icons/io";
import { trackEvent } from "../../analytics";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import ImageSkeleton from "../Skeleton/ImageSkeleton";
import { handleNativeShare } from "../utils";
import "./media.card.scss";

const MediaCard = ({ isImage, mediaSource, title, handleLike, hasLiked, handleBookmark, hasBookmarked, isProductPage = false, link, pageViews, pageLikes }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);

  const handleShare = () => {
    handleNativeShare(title, window.location.href);
    trackEvent('Share');
  };

  return (
    <>
      <div className="media__card__container shadow-sm menu__spacer">
        {isImage ? (
          <>
            {!isMediaLoaded && <ImageSkeleton />}
            <div className={`media__card__img ${isMediaLoaded ? "d-block" : "d-none"}`}>
              <img src={IMAGE_SERVER_PATH +  mediaSource} alt={title} onLoad={() => setIsMediaLoaded(true)} />
            </div>
          </>
        ) : (
          <>
            {!isMediaLoaded && <ImageSkeleton />}
            <div className={`media__card__video ${isMediaLoaded ? "d-block" : "d-none"}`}>
              <iframe
                src={mediaSource}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={title}
                onLoad={() => setIsMediaLoaded(true)}
              />
            </div>
          </>
        )}
        <div className="media__card__content__container">
          <div className={`media__card__title__wrapper ${isProductPage && "pb-0"}`}>
            <h1>{title}</h1>
            {isProductPage && (
              <a href={link} rel="noreferrer" target="_blank">
                Vist Store
              </a>
            )}
          </div>
          {!isProductPage ? (
            <div className="media__card__body">
              <div className="media__card__metrics__system">
                {/* <div className="media__card__likes">
                  <AiFillHeart /> <span>{formatCount(pageLikes)}</span>
                </div>
                <div className="media__card__views">
                  <AiFillEye /> <span>{formatCount(pageViews)}</span>
                </div> */}
              </div>
              <div className="media__card__external">
                <motion.button className={`media__card__actions ${hasLiked ? "fill__state" : ""}`} whileTap={{ scale: 0.8 }} onClick={handleLike}>
                  <AiFillHeart />
                </motion.button>
                {/* <motion.button
                  className={`media__card__actions ${hasBookmarked ? "fill__state" : ""}`}
                  whileTap={{ scale: 0.8 }}
                  onClick={handleBookmark}
                >
                  <BsBookmarkPlusFill />
                </motion.button> */}
                <motion.button className="media__card__actions" whileTap={{ scale: 0.8 }} onClick={handleShare}>
                  <IoIosShareAlt />
                </motion.button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default MediaCard;
