import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { trackEvent } from "../analytics";
import ListCard from "../components/Cards/ListCard";
import TitleCard from "../components/Cards/TitleCards";
import Carousel from "../components/Carousels/Carousel";
import CategoryHero from "../components/Hero/CategoryHero";
import { LayoutSection } from "../components/Misc";
import CategorySkeleton from "../components/Skeleton/CategorySkeleton";
import { Layout } from "../components/StyledComponents/index";
import { CATEGORY, PREFS } from "../components/utils";
import PageNotFound from "../pages/PageNotFound";
import Page from "../routes/PageLayout";
import { PRODUCTS } from "../routes/PathConfig";
import { updateLikes, updateViews } from "../store/reducer/database";

const Category = () => {
  const { category, lang } = useParams();
  const [like, setLike] = useState(false);
  const dispatch = useDispatch();
  const { data, isDataLoading, language, isEnglish } = useSelector((state) => state.database);
  const hasLiked = JSON.parse(localStorage.getItem(PREFS))?.[language].pageLikes?.category;

  useEffect(() => {
    dispatch(updateViews({ page: CATEGORY, category }));
    setLike(hasLiked?.includes(category));
    // eslint-disable-next-line
  }, [category]);

  const handleLikesClick = () => {
    dispatch(updateLikes({ page: CATEGORY, category }));
    setLike(!like);
    trackEvent('category_like_'+category);
  };

  const categoryContent = data?.BakingSodaContent?.[category]?.CategoryData;
  const categoryUses = data?.BakingSodaContent?.[category]?.UsesData;

  if (data?.BakingSodaContent && !categoryContent && !categoryUses) {
    return <PageNotFound />;
  }

  const TRANS = window.TRANS;
  return (
    <Page title={TRANS[category?.replaceAll("-", "_")]}
      {...(categoryContent?.Media_Type === 'image' && { imageSource: categoryContent?.Media_Source })}
    >
      {categoryContent && !isDataLoading ? (
        <>
          <CategoryHero image={categoryContent?.Media_Source} alt={categoryContent?.alt} />
          <Layout className="category__wrapper mb-5">
            <TitleCard
              title={isEnglish ? categoryContent?.Title : categoryContent?.Title_Ar}
              handleLike={handleLikesClick}
              hasLiked={like}
              pageLikes={categoryContent?.pageLikes || 1}
              pageViews={categoryContent?.pageViews || 1}
            />
            {Object.entries(categoryUses).map(([id, value], i) => (
              <ListCard
                key={id}
                link={id}
                title={isEnglish ? value.Title : value.Title_Ar}
                path={`${lang}/explore/${category}`}
                index={i}
                isEnglish={isEnglish}
              />
            ))}
          </Layout>
          <LayoutSection title="Products" link={PRODUCTS}>
            <Carousel source={data?.Products} id="home__products" />
          </LayoutSection>
        </>
      ) : (
        <CategorySkeleton />
      )}
    </Page>
  );
};

export default Category;
