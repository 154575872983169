import React from "react";
import "./skeleton.scss";
import ContentLoader from "react-content-loader";

const SubCategorySkeleton = ({ height = 240, ...props }) => {
  return (
    <ContentLoader speed={2} width="100%" height={height} backgroundColor="#e8e8e8" foregroundColor="#dddddd" {...props}>
      <rect x="0" y="0" rx="9" ry="9" width="100%" height={`${height}px`} />
    </ContentLoader>
  );
};

export default SubCategorySkeleton;
