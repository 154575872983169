import React, { useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
//import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { trackEvent } from "../../analytics";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import ImageSkeleton from "../Skeleton/ImageSkeleton";
import "./product.card.scss";

const ProductCard = ({ item, isEnglish }) => {
  const { Product_Link_Ar, Product_Link, Media_Source, Title, Title_Ar, Description_Title, Description_Title_Ar, Product_ID } = item;
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);


  return (
    <div className="product__card__container">
      <motion.div whileTap={{ scale: 0.98 }}>
        <a
          href={isEnglish ? Product_Link : Product_Link_Ar}
          target="_blank"
          rel="noreferrer"
          onClick={() => trackEvent(`Product_${Product_ID}_${Title}`)}
        >
          {/* <Link
          to={url}
          onClick={() => trackEvent(`Product_${Product_ID}_${Title}`)}
        > */}
          {!isMediaLoaded && <ImageSkeleton height={160} />}
          <img
            className={`product__card__img ${
              isMediaLoaded ? "d-block" : "d-none"
            }`}
            src={IMAGE_SERVER_PATH +  Media_Source}
            alt={Title}
            onLoad={() => setIsMediaLoaded(true)}
          />
          <div className="product__card__content__container">
            <h5 className="product__card__title">{isEnglish ? Title : Title_Ar}</h5>
            <p className="product__card__desc">{isEnglish ? Description_Title : Description_Title_Ar}</p>
            <div className="product__card__navigation">
              <BsArrowRightShort />
            </div>
          </div>
          {/* </Link> */}
        </a>
      </motion.div>
    </div>
  );
};
export default ProductCard;
