import { motion } from "framer-motion";
import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { trackEvent } from "../../analytics";
import { EN_LANG } from "../../routes/PathConfig";
import { IMAGE_SERVER_PATH } from "../../store/reducer/constants";
import { getCurrentLanguage } from "../../store/selector";
import "./carousel.scss";

const Carousel = ({ source, slidesPerView = 1.3, id, indicators = false, isEnglish = getCurrentLanguage() === EN_LANG }) => {  
  return (
    <>
      {source && (
        <div className="swiper__split__carousel">
          <Swiper
            modules={[Pagination]}
            spaceBetween={12}
            slidesPerView={slidesPerView}
            
            pagination={{
              el: `#${id}`,
              clickable: true,
            }}
          >
            {Object.entries(source).map(
              ([key, { Title, Title_Ar, Media_Source, Product_Description, Product_Link, Product_Link_Ar, Product_ID, Description_Title, Description_Title_Ar }]) => (
                <SwiperSlide key={Product_ID}>
                  <motion.div whileTap={{ scale: 0.98 }} className='carousel__media__image'>
                    <img src={IMAGE_SERVER_PATH + Media_Source} alt="carousel-background" />
                    <div className="carousel__content__container">
                      <a
                        href={isEnglish ? Product_Link : Product_Link_Ar}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          trackEvent('Product_Cards_' + Title)
                        }
                      >
                        <h5 className="carousel__title">{isEnglish ? Title : Title_Ar}</h5>
                        <p className="carousel__desc">{isEnglish ? Description_Title : Description_Title_Ar}</p>
                        <div className="carousel__navigation">
                          <BsArrowRightShort />
                        </div>
                      </a>
                    </div>
                  </motion.div>
                </SwiperSlide>
              )
            )}
          </Swiper>
          {indicators && <div id={id}></div>}
        </div>
      )}
    </>
  );
};
export default React.memo(Carousel);
