import { motion } from "framer-motion";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./misc.scss";

export const FeelingLucky = ({ click }) => {
  const TRANS = window.TRANS; 
  return (
    <>
      <motion.div className="lucky__container" whileTap={{ scale: 0.9 }} onClick={click}>
        <div className="lucky__context">
          <h4>{TRANS.feeling}</h4>
          <p>{TRANS.feelingdesc}</p>
        </div>
        <div className="lucky__arrow">
          <BiRightArrowAlt />
        </div>
      </motion.div>
    </>
  );
};

export const TitleHolder = ({ title, link, cta, externalLink = false }) => {
  return (
    <div className="layout__section__title">
      <h3>{title}</h3>
      {link && !externalLink ? (
        <Link to={link}>{cta}</Link>
      ) : (
        <a href={link} target="_blank" rel="noreferrer">
          {cta}
        </a>
      )}
    </div>
  );
};

export const LayoutSection = ({ link = "/", title, cta, children, pr = false, bg = true }) => {
  const TRANS = window.TRANS;
  return (
    <>
      <div className={`layout__section__container ${bg ? "ash__bg" : ""}`} {...(!pr && { style: { paddingRight: "0" } })}>
        {title && <TitleHolder link={link} title={title} cta={cta ?? TRANS.ViewAll} />}
        {children}
      </div>
    </>
  );
};
