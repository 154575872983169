import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { pageView } from "../analytics";
import { Footer } from "../components/Header_Footer";
import { appBarTitle } from "../store/reducer/app";
import { IMAGE_SERVER_PATH } from "../store/reducer/constants";

const transition = { ease: "easeInOut", duration: 0.3 };

export default function Page({ children, title = "ARM & HAMMER™", isFooter = true, imageSource, ...props }) {
  const rootPath = window.location.pathname === "/";
  const params = useParams();
  const dispatch = useDispatch();
  /**
   * If the path is root then redirect to default lang page
   */
  if (rootPath) window.history.pushState(null, null, params?.lang ? `/${params.lang}/` : '/en/');

  useEffect(() => {
    dispatch(appBarTitle(title));
    pageView(window.location.pathname)
    // eslint-disable-next-line
  }, []);
  const SiteAddress = 'https://app.armandhammerarabia.com/';
  const SiteName = 'ARM & HAMMER™';
  const PageTitle = title === SiteName ? SiteName : title + ' | ' + SiteName;
  const PageDescription = 'Discover 100 amazing uses of baking soda for cleaning, cooking, and more. Transform your home and lifestyle with this versatile household staple.';
  const PageImageSource = imageSource ? IMAGE_SERVER_PATH + imageSource : '/baking-soda.jpg';
  
  return (
    <React.Fragment>
       <Helmet>
        <title>{PageTitle}</title>
        <meta name="date" content={new Date().toDateString()} />
        <link rel="canonical" href={SiteAddress} />
        <meta name="description" content={PageDescription} />
        <meta name="keywords" content="baking soda, uses, cleaning, cooking, natural remedies" />
        {/* META SEO */}
        <meta property="og:title" content={PageTitle} />
        <meta property="og:description" content={PageDescription} />
        <meta property="og:image" content={PageImageSource} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content={SiteName} />
        <meta property="og:url" content={SiteAddress} />
        <meta property="og:type" content="website" />
        {/* META SEO */}

        {/* Twitter SEO */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={PageTitle} />
        <meta name="twitter:description" content={PageDescription}/>
        <meta name="twitter:image" content={PageImageSource}/>
        {/* Twitter SEO */}
      </Helmet>
      <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={transition} {...props}>
        {children}
        {isFooter && <Footer />}
      </motion.main>
    </React.Fragment>
  );
}
