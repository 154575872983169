import { motion } from "framer-motion";
import React from "react";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { trackEvent } from "../../analytics";
import { getCurrentLanguage } from "../../store/selector";
import "./category.carousel.scss";

const CategoryBlocks = ({ source, slidesPerView = 2.5, id, indicators = false }) => {
  return (
    <>
      {source && (
        <div className="category__grid__container">
            {source.map(({ name, iconSrc, slug, description }, idx) => (
                <motion.div whileTap={{ scale: 0.98 }} key={slug} className="category__container">
                  <Link
                    to={`/${getCurrentLanguage()}/explore/${slug}`}
                    onClick={() => trackEvent('Category_Click_' + slug)}
                  >
                    <div className="carousel__content__container">
                      <IoArrowForwardOutline className="card__icon__arrow"/>
                      <div className="carousel__img__container">
                        <img src={iconSrc} alt={name} />
                      </div>
                      <h5 className="carousel__title">{name}</h5> 
                      <div className="category__carousel__desc">{description}</div>                     
                    </div>
                  </Link>
                </motion.div>
            ))}
        </div>
      )}
    </>
  );
};

export const CategoryBlock = React.memo(CategoryBlocks)
