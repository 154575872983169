import { motion } from "framer-motion";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../store/selector";

const SearchCards = ({ data, isEnglish }) => {
  const TRANS = window.TRANS;
  return (
    <>
      {data.length > 0 &&
        data.map((it, index) => (
          <Link to={`/${getCurrentLanguage()}/explore/${it.Category_ID}/${it.id}`} className="anchor__reset" key={it.id + index}>
            <motion.div whileTap={{ scale: 0.97 }}>
              <div className="des__card__container shadow-sm">
                <ul className="des__card__description mb-0">
                  {<li>{isEnglish ? it.Title : it.Title_Ar}</li> }
                  <div className="d-flex justify-content-between">
                    <div className="tag tag__green category__tag">{TRANS[it.Category_ID?.replaceAll('-', '_')]}</div>
                    <IoIosArrowForward className="search_result_arrow arrow__ion__ico"/>
                  </div>
                </ul>
              </div>
            </motion.div>
          </Link>
        ))}
      <hr className="mt-4 mb-5 mx-5" />
    </>
  );
};
export default SearchCards;
